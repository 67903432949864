export const TOAST_DURATION = 3000;

export const PAGE_SIZE = 9;

export const CASE_LAW_START_YEAR = 1993;

// Pattern for tel number (+420)111222333
export const TEL_RGX = /^\s*?(\+\s*?4\s*?2\s*?0\s*?)?(\s*?\d\s*?){9}$/;

export const VERIFIED_DOMAINS = [
  // non-government
  "epravo.cz",
  "profipravo.cz",
  "pravniprostor.cz",
  "advokatnidenik.cz",
  "ceska-justice.cz",
  "lawyersandbusiness.cz",
  "aspi.cz",
  // government
  "mpsv.cz",
  "mdcr.cz",
  "mvcr.cz",
  "justice.cz",
  "mfcr.cz",
  "army.cz",
  "mmr.cz",
  "mpo.cz",
  "msmt.cz",
  "mvcr.cz",
  "mzv.cz",
  "mzcr.cz",
  "mze.cz",
  "env.cz",
  "vlada.cz",
  "psp.cz",
  "senat.cz",
  "cnb.cz",
  "nipos.cz",
  "uzis.cz",
  "uradprace.cz",
  "celnisprava.cz",
  "ctu.cz",
  "portal.gov.cz",
  "uohs.gov.cz",
  "eagri.cz",
  "dotaceeu.cz",
  "czechinvest.org",
];
