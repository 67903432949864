import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  bpchar: { input: any; output: any };
  date: { input: any; output: any };
  jsonb: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _gte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lte?: InputMaybe<Scalars["Boolean"]["input"]>;
  _neq?: InputMaybe<Scalars["Boolean"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type CheckTokenResponse = {
  __typename?: "CheckTokenResponse";
  tokenValid: Scalars["Boolean"]["output"];
  userId: Scalars["String"]["output"];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]["input"]>;
  _gt?: InputMaybe<Scalars["Int"]["input"]>;
  _gte?: InputMaybe<Scalars["Int"]["input"]>;
  _in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["Int"]["input"]>;
  _lte?: InputMaybe<Scalars["Int"]["input"]>;
  _neq?: InputMaybe<Scalars["Int"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _eq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _gt?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _gte?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Array<Scalars["String"]["input"]>>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _lte?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _neq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _nin?: InputMaybe<Array<Array<Scalars["String"]["input"]>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]["input"]>;
  _gt?: InputMaybe<Scalars["String"]["input"]>;
  _gte?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]["input"]>;
  _in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]["input"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]["input"]>;
  _lt?: InputMaybe<Scalars["String"]["input"]>;
  _lte?: InputMaybe<Scalars["String"]["input"]>;
  _neq?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]["input"]>;
};

export type TokenResponse = {
  __typename?: "TokenResponse";
  token: Scalars["String"]["output"];
};

export type VerifyEmailResponse = {
  __typename?: "VerifyEmailResponse";
  email: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["bpchar"]["input"]>;
  _gt?: InputMaybe<Scalars["bpchar"]["input"]>;
  _gte?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["bpchar"]["input"]>;
  _in?: InputMaybe<Array<Scalars["bpchar"]["input"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["bpchar"]["input"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["bpchar"]["input"]>;
  _lt?: InputMaybe<Scalars["bpchar"]["input"]>;
  _lte?: InputMaybe<Scalars["bpchar"]["input"]>;
  _neq?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["bpchar"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["bpchar"]["input"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["bpchar"]["input"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["bpchar"]["input"]>;
};

/** columns and relationships of "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud = {
  __typename?: "case_law_ids_nsoud";
  id: Scalars["String"]["output"];
};

/** aggregated selection of "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Aggregate = {
  __typename?: "case_law_ids_nsoud_aggregate";
  aggregate?: Maybe<Case_Law_Ids_Nsoud_Aggregate_Fields>;
  nodes: Array<Case_Law_Ids_Nsoud>;
};

/** aggregate fields of "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Aggregate_Fields = {
  __typename?: "case_law_ids_nsoud_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Case_Law_Ids_Nsoud_Max_Fields>;
  min?: Maybe<Case_Law_Ids_Nsoud_Min_Fields>;
};

/** aggregate fields of "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Law_Ids_Nsoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "case_law_ids_nsoud". All fields are combined with a logical 'AND'. */
export type Case_Law_Ids_Nsoud_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Law_Ids_Nsoud_Bool_Exp>>;
  _not?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Law_Ids_Nsoud_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_law_ids_nsoud" */
export enum Case_Law_Ids_Nsoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseLawIdsNsoudPkey = "case_law_ids_nsoud_pkey",
}

/** input type for inserting data into table "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Insert_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Case_Law_Ids_Nsoud_Max_Fields = {
  __typename?: "case_law_ids_nsoud_max_fields";
  id?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Case_Law_Ids_Nsoud_Min_Fields = {
  __typename?: "case_law_ids_nsoud_min_fields";
  id?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Mutation_Response = {
  __typename?: "case_law_ids_nsoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Law_Ids_Nsoud>;
};

/** on_conflict condition type for table "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_On_Conflict = {
  constraint: Case_Law_Ids_Nsoud_Constraint;
  update_columns?: Array<Case_Law_Ids_Nsoud_Update_Column>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

/** Ordering options when selecting data from "case_law_ids_nsoud". */
export type Case_Law_Ids_Nsoud_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_law_ids_nsoud */
export type Case_Law_Ids_Nsoud_Pk_Columns_Input = {
  id: Scalars["String"]["input"];
};

/** select columns of table "case_law_ids_nsoud" */
export enum Case_Law_Ids_Nsoud_Select_Column {
  /** column name */
  Id = "id",
}

/** input type for updating data in table "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Set_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "case_law_ids_nsoud" */
export type Case_Law_Ids_Nsoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Law_Ids_Nsoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Law_Ids_Nsoud_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "case_law_ids_nsoud" */
export enum Case_Law_Ids_Nsoud_Update_Column {
  /** column name */
  Id = "id",
}

export type Case_Law_Ids_Nsoud_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Law_Ids_Nsoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Law_Ids_Nsoud_Bool_Exp;
};

/** columns and relationships of "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud = {
  __typename?: "case_law_ids_nssoud";
  id: Scalars["Int"]["output"];
};

/** aggregated selection of "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Aggregate = {
  __typename?: "case_law_ids_nssoud_aggregate";
  aggregate?: Maybe<Case_Law_Ids_Nssoud_Aggregate_Fields>;
  nodes: Array<Case_Law_Ids_Nssoud>;
};

/** aggregate fields of "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Aggregate_Fields = {
  __typename?: "case_law_ids_nssoud_aggregate_fields";
  avg?: Maybe<Case_Law_Ids_Nssoud_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Case_Law_Ids_Nssoud_Max_Fields>;
  min?: Maybe<Case_Law_Ids_Nssoud_Min_Fields>;
  stddev?: Maybe<Case_Law_Ids_Nssoud_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Law_Ids_Nssoud_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Law_Ids_Nssoud_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Law_Ids_Nssoud_Sum_Fields>;
  var_pop?: Maybe<Case_Law_Ids_Nssoud_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Law_Ids_Nssoud_Var_Samp_Fields>;
  variance?: Maybe<Case_Law_Ids_Nssoud_Variance_Fields>;
};

/** aggregate fields of "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Law_Ids_Nssoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Case_Law_Ids_Nssoud_Avg_Fields = {
  __typename?: "case_law_ids_nssoud_avg_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "case_law_ids_nssoud". All fields are combined with a logical 'AND'. */
export type Case_Law_Ids_Nssoud_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Law_Ids_Nssoud_Bool_Exp>>;
  _not?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Law_Ids_Nssoud_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_law_ids_nssoud" */
export enum Case_Law_Ids_Nssoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseLawIdsNssoudPkey = "case_law_ids_nssoud_pkey",
}

/** input type for incrementing numeric columns in table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type Case_Law_Ids_Nssoud_Max_Fields = {
  __typename?: "case_law_ids_nssoud_max_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type Case_Law_Ids_Nssoud_Min_Fields = {
  __typename?: "case_law_ids_nssoud_min_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Mutation_Response = {
  __typename?: "case_law_ids_nssoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Law_Ids_Nssoud>;
};

/** on_conflict condition type for table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_On_Conflict = {
  constraint: Case_Law_Ids_Nssoud_Constraint;
  update_columns?: Array<Case_Law_Ids_Nssoud_Update_Column>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

/** Ordering options when selecting data from "case_law_ids_nssoud". */
export type Case_Law_Ids_Nssoud_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_law_ids_nssoud */
export type Case_Law_Ids_Nssoud_Pk_Columns_Input = {
  id: Scalars["Int"]["input"];
};

/** select columns of table "case_law_ids_nssoud" */
export enum Case_Law_Ids_Nssoud_Select_Column {
  /** column name */
  Id = "id",
}

/** input type for updating data in table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Set_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type Case_Law_Ids_Nssoud_Stddev_Fields = {
  __typename?: "case_law_ids_nssoud_stddev_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Case_Law_Ids_Nssoud_Stddev_Pop_Fields = {
  __typename?: "case_law_ids_nssoud_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Case_Law_Ids_Nssoud_Stddev_Samp_Fields = {
  __typename?: "case_law_ids_nssoud_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "case_law_ids_nssoud" */
export type Case_Law_Ids_Nssoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Law_Ids_Nssoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Law_Ids_Nssoud_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type Case_Law_Ids_Nssoud_Sum_Fields = {
  __typename?: "case_law_ids_nssoud_sum_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "case_law_ids_nssoud" */
export enum Case_Law_Ids_Nssoud_Update_Column {
  /** column name */
  Id = "id",
}

export type Case_Law_Ids_Nssoud_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Case_Law_Ids_Nssoud_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Law_Ids_Nssoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Law_Ids_Nssoud_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Law_Ids_Nssoud_Var_Pop_Fields = {
  __typename?: "case_law_ids_nssoud_var_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Case_Law_Ids_Nssoud_Var_Samp_Fields = {
  __typename?: "case_law_ids_nssoud_var_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Case_Law_Ids_Nssoud_Variance_Fields = {
  __typename?: "case_law_ids_nssoud_variance_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud = {
  __typename?: "case_law_ids_usoud";
  id: Scalars["Int"]["output"];
};

/** aggregated selection of "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Aggregate = {
  __typename?: "case_law_ids_usoud_aggregate";
  aggregate?: Maybe<Case_Law_Ids_Usoud_Aggregate_Fields>;
  nodes: Array<Case_Law_Ids_Usoud>;
};

/** aggregate fields of "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Aggregate_Fields = {
  __typename?: "case_law_ids_usoud_aggregate_fields";
  avg?: Maybe<Case_Law_Ids_Usoud_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Case_Law_Ids_Usoud_Max_Fields>;
  min?: Maybe<Case_Law_Ids_Usoud_Min_Fields>;
  stddev?: Maybe<Case_Law_Ids_Usoud_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Law_Ids_Usoud_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Law_Ids_Usoud_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Law_Ids_Usoud_Sum_Fields>;
  var_pop?: Maybe<Case_Law_Ids_Usoud_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Law_Ids_Usoud_Var_Samp_Fields>;
  variance?: Maybe<Case_Law_Ids_Usoud_Variance_Fields>;
};

/** aggregate fields of "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Case_Law_Ids_Usoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Case_Law_Ids_Usoud_Avg_Fields = {
  __typename?: "case_law_ids_usoud_avg_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "case_law_ids_usoud". All fields are combined with a logical 'AND'. */
export type Case_Law_Ids_Usoud_Bool_Exp = {
  _and?: InputMaybe<Array<Case_Law_Ids_Usoud_Bool_Exp>>;
  _not?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
  _or?: InputMaybe<Array<Case_Law_Ids_Usoud_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_law_ids_usoud" */
export enum Case_Law_Ids_Usoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseLawIdsUsoudPkey = "case_law_ids_usoud_pkey",
}

/** input type for incrementing numeric columns in table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Insert_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate max on columns */
export type Case_Law_Ids_Usoud_Max_Fields = {
  __typename?: "case_law_ids_usoud_max_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate min on columns */
export type Case_Law_Ids_Usoud_Min_Fields = {
  __typename?: "case_law_ids_usoud_min_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** response of any mutation on the table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Mutation_Response = {
  __typename?: "case_law_ids_usoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Law_Ids_Usoud>;
};

/** on_conflict condition type for table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_On_Conflict = {
  constraint: Case_Law_Ids_Usoud_Constraint;
  update_columns?: Array<Case_Law_Ids_Usoud_Update_Column>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

/** Ordering options when selecting data from "case_law_ids_usoud". */
export type Case_Law_Ids_Usoud_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: case_law_ids_usoud */
export type Case_Law_Ids_Usoud_Pk_Columns_Input = {
  id: Scalars["Int"]["input"];
};

/** select columns of table "case_law_ids_usoud" */
export enum Case_Law_Ids_Usoud_Select_Column {
  /** column name */
  Id = "id",
}

/** input type for updating data in table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Set_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate stddev on columns */
export type Case_Law_Ids_Usoud_Stddev_Fields = {
  __typename?: "case_law_ids_usoud_stddev_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Case_Law_Ids_Usoud_Stddev_Pop_Fields = {
  __typename?: "case_law_ids_usoud_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Case_Law_Ids_Usoud_Stddev_Samp_Fields = {
  __typename?: "case_law_ids_usoud_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "case_law_ids_usoud" */
export type Case_Law_Ids_Usoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Law_Ids_Usoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Law_Ids_Usoud_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** aggregate sum on columns */
export type Case_Law_Ids_Usoud_Sum_Fields = {
  __typename?: "case_law_ids_usoud_sum_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "case_law_ids_usoud" */
export enum Case_Law_Ids_Usoud_Update_Column {
  /** column name */
  Id = "id",
}

export type Case_Law_Ids_Usoud_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Case_Law_Ids_Usoud_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Case_Law_Ids_Usoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Law_Ids_Usoud_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Law_Ids_Usoud_Var_Pop_Fields = {
  __typename?: "case_law_ids_usoud_var_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Case_Law_Ids_Usoud_Var_Samp_Fields = {
  __typename?: "case_law_ids_usoud_var_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Case_Law_Ids_Usoud_Variance_Fields = {
  __typename?: "case_law_ids_usoud_variance_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]["input"]>;
  _gt?: InputMaybe<Scalars["date"]["input"]>;
  _gte?: InputMaybe<Scalars["date"]["input"]>;
  _in?: InputMaybe<Array<Scalars["date"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["date"]["input"]>;
  _lte?: InputMaybe<Scalars["date"]["input"]>;
  _neq?: InputMaybe<Scalars["date"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["date"]["input"]>>;
};

/** columns and relationships of "email_verification_requests" */
export type Email_Verification_Requests = {
  __typename?: "email_verification_requests";
  created_at: Scalars["timestamptz"]["output"];
  email: Scalars["String"]["output"];
  finished: Scalars["Boolean"]["output"];
  id: Scalars["uuid"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  user_rel: Users;
  userid: Scalars["uuid"]["output"];
};

/** aggregated selection of "email_verification_requests" */
export type Email_Verification_Requests_Aggregate = {
  __typename?: "email_verification_requests_aggregate";
  aggregate?: Maybe<Email_Verification_Requests_Aggregate_Fields>;
  nodes: Array<Email_Verification_Requests>;
};

export type Email_Verification_Requests_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Email_Verification_Requests_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Email_Verification_Requests_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Email_Verification_Requests_Aggregate_Bool_Exp_Count>;
};

export type Email_Verification_Requests_Aggregate_Bool_Exp_Bool_And = {
  arguments: Email_Verification_Requests_Select_Column_Email_Verification_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Email_Verification_Requests_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Email_Verification_Requests_Select_Column_Email_Verification_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Email_Verification_Requests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "email_verification_requests" */
export type Email_Verification_Requests_Aggregate_Fields = {
  __typename?: "email_verification_requests_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Email_Verification_Requests_Max_Fields>;
  min?: Maybe<Email_Verification_Requests_Min_Fields>;
};

/** aggregate fields of "email_verification_requests" */
export type Email_Verification_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "email_verification_requests" */
export type Email_Verification_Requests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Email_Verification_Requests_Max_Order_By>;
  min?: InputMaybe<Email_Verification_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_verification_requests" */
export type Email_Verification_Requests_Arr_Rel_Insert_Input = {
  data: Array<Email_Verification_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Verification_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_verification_requests". All fields are combined with a logical 'AND'. */
export type Email_Verification_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Verification_Requests_Bool_Exp>>;
  _not?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Verification_Requests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  finished?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_rel?: InputMaybe<Users_Bool_Exp>;
  userid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_verification_requests" */
export enum Email_Verification_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailVerificationRequestsPkey = "email_verification_requests_pkey",
}

/** input type for inserting data into table "email_verification_requests" */
export type Email_Verification_Requests_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_rel?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Email_Verification_Requests_Max_Fields = {
  __typename?: "email_verification_requests_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  userid?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "email_verification_requests" */
export type Email_Verification_Requests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Verification_Requests_Min_Fields = {
  __typename?: "email_verification_requests_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  userid?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "email_verification_requests" */
export type Email_Verification_Requests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "email_verification_requests" */
export type Email_Verification_Requests_Mutation_Response = {
  __typename?: "email_verification_requests_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Verification_Requests>;
};

/** on_conflict condition type for table "email_verification_requests" */
export type Email_Verification_Requests_On_Conflict = {
  constraint: Email_Verification_Requests_Constraint;
  update_columns?: Array<Email_Verification_Requests_Update_Column>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "email_verification_requests". */
export type Email_Verification_Requests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  finished?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_rel?: InputMaybe<Users_Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_verification_requests */
export type Email_Verification_Requests_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "email_verification_requests" */
export enum Email_Verification_Requests_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Finished = "finished",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Userid = "userid",
}

/** select "email_verification_requests_aggregate_bool_exp_bool_and_arguments_columns" columns of table "email_verification_requests" */
export enum Email_Verification_Requests_Select_Column_Email_Verification_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Finished = "finished",
}

/** select "email_verification_requests_aggregate_bool_exp_bool_or_arguments_columns" columns of table "email_verification_requests" */
export enum Email_Verification_Requests_Select_Column_Email_Verification_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Finished = "finished",
}

/** input type for updating data in table "email_verification_requests" */
export type Email_Verification_Requests_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "email_verification_requests" */
export type Email_Verification_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Verification_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Verification_Requests_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "email_verification_requests" */
export enum Email_Verification_Requests_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Finished = "finished",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Userid = "userid",
}

export type Email_Verification_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Verification_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Verification_Requests_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]["input"]>;
  _eq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _gte?: InputMaybe<Scalars["jsonb"]["input"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]["input"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]["input"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["jsonb"]["input"]>;
  _lte?: InputMaybe<Scalars["jsonb"]["input"]>;
  _neq?: InputMaybe<Scalars["jsonb"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]["input"]>>;
};

/** columns and relationships of "legislation_ids" */
export type Legislation_Ids = {
  __typename?: "legislation_ids";
  id: Scalars["String"]["output"];
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregated selection of "legislation_ids" */
export type Legislation_Ids_Aggregate = {
  __typename?: "legislation_ids_aggregate";
  aggregate?: Maybe<Legislation_Ids_Aggregate_Fields>;
  nodes: Array<Legislation_Ids>;
};

/** aggregate fields of "legislation_ids" */
export type Legislation_Ids_Aggregate_Fields = {
  __typename?: "legislation_ids_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Legislation_Ids_Max_Fields>;
  min?: Maybe<Legislation_Ids_Min_Fields>;
};

/** aggregate fields of "legislation_ids" */
export type Legislation_Ids_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Legislation_Ids_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "legislation_ids". All fields are combined with a logical 'AND'. */
export type Legislation_Ids_Bool_Exp = {
  _and?: InputMaybe<Array<Legislation_Ids_Bool_Exp>>;
  _not?: InputMaybe<Legislation_Ids_Bool_Exp>;
  _or?: InputMaybe<Array<Legislation_Ids_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "legislation_ids" */
export enum Legislation_Ids_Constraint {
  /** unique or primary key constraint on columns "id" */
  LegislationIdsPkey = "legislation_ids_pkey",
}

/** input type for inserting data into table "legislation_ids" */
export type Legislation_Ids_Insert_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Legislation_Ids_Max_Fields = {
  __typename?: "legislation_ids_max_fields";
  id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Legislation_Ids_Min_Fields = {
  __typename?: "legislation_ids_min_fields";
  id?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "legislation_ids" */
export type Legislation_Ids_Mutation_Response = {
  __typename?: "legislation_ids_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Legislation_Ids>;
};

/** on_conflict condition type for table "legislation_ids" */
export type Legislation_Ids_On_Conflict = {
  constraint: Legislation_Ids_Constraint;
  update_columns?: Array<Legislation_Ids_Update_Column>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

/** Ordering options when selecting data from "legislation_ids". */
export type Legislation_Ids_Order_By = {
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: legislation_ids */
export type Legislation_Ids_Pk_Columns_Input = {
  id: Scalars["String"]["input"];
};

/** select columns of table "legislation_ids" */
export enum Legislation_Ids_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "legislation_ids" */
export type Legislation_Ids_Set_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "legislation_ids" */
export type Legislation_Ids_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Legislation_Ids_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Legislation_Ids_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "legislation_ids" */
export enum Legislation_Ids_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Legislation_Ids_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Legislation_Ids_Set_Input>;
  /** filter the rows which have to be updated */
  where: Legislation_Ids_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  activate_account: TokenResponse;
  change_password: Scalars["String"]["output"];
  /** delete data from the table: "case_law_ids_nsoud" */
  delete_case_law_ids_nsoud?: Maybe<Case_Law_Ids_Nsoud_Mutation_Response>;
  /** delete single row from the table: "case_law_ids_nsoud" */
  delete_case_law_ids_nsoud_by_pk?: Maybe<Case_Law_Ids_Nsoud>;
  /** delete data from the table: "case_law_ids_nssoud" */
  delete_case_law_ids_nssoud?: Maybe<Case_Law_Ids_Nssoud_Mutation_Response>;
  /** delete single row from the table: "case_law_ids_nssoud" */
  delete_case_law_ids_nssoud_by_pk?: Maybe<Case_Law_Ids_Nssoud>;
  /** delete data from the table: "case_law_ids_usoud" */
  delete_case_law_ids_usoud?: Maybe<Case_Law_Ids_Usoud_Mutation_Response>;
  /** delete single row from the table: "case_law_ids_usoud" */
  delete_case_law_ids_usoud_by_pk?: Maybe<Case_Law_Ids_Usoud>;
  /** delete data from the table: "email_verification_requests" */
  delete_email_verification_requests?: Maybe<Email_Verification_Requests_Mutation_Response>;
  /** delete single row from the table: "email_verification_requests" */
  delete_email_verification_requests_by_pk?: Maybe<Email_Verification_Requests>;
  /** delete data from the table: "legislation_ids" */
  delete_legislation_ids?: Maybe<Legislation_Ids_Mutation_Response>;
  /** delete single row from the table: "legislation_ids" */
  delete_legislation_ids_by_pk?: Maybe<Legislation_Ids>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "password_reset_requests" */
  delete_password_reset_requests?: Maybe<Password_Reset_Requests_Mutation_Response>;
  /** delete single row from the table: "password_reset_requests" */
  delete_password_reset_requests_by_pk?: Maybe<Password_Reset_Requests>;
  /** delete data from the table: "raw_case_law_nsoud" */
  delete_raw_case_law_nsoud?: Maybe<Raw_Case_Law_Nsoud_Mutation_Response>;
  /** delete single row from the table: "raw_case_law_nsoud" */
  delete_raw_case_law_nsoud_by_pk?: Maybe<Raw_Case_Law_Nsoud>;
  /** delete data from the table: "raw_case_law_nssoud" */
  delete_raw_case_law_nssoud?: Maybe<Raw_Case_Law_Nssoud_Mutation_Response>;
  /** delete single row from the table: "raw_case_law_nssoud" */
  delete_raw_case_law_nssoud_by_pk?: Maybe<Raw_Case_Law_Nssoud>;
  /** delete data from the table: "raw_case_law_usoud" */
  delete_raw_case_law_usoud?: Maybe<Raw_Case_Law_Usoud_Mutation_Response>;
  /** delete single row from the table: "raw_case_law_usoud" */
  delete_raw_case_law_usoud_by_pk?: Maybe<Raw_Case_Law_Usoud>;
  /** delete data from the table: "raw_legislation" */
  delete_raw_legislation?: Maybe<Raw_Legislation_Mutation_Response>;
  /** delete single row from the table: "raw_legislation" */
  delete_raw_legislation_by_pk?: Maybe<Raw_Legislation>;
  /** delete data from the table: "research" */
  delete_research?: Maybe<Research_Mutation_Response>;
  /** delete single row from the table: "research" */
  delete_research_by_pk?: Maybe<Research>;
  /** delete data from the table: "scraper_state" */
  delete_scraper_state?: Maybe<Scraper_State_Mutation_Response>;
  /** delete single row from the table: "scraper_state" */
  delete_scraper_state_by_pk?: Maybe<Scraper_State>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_favorite" */
  delete_users_favorite?: Maybe<Users_Favorite_Mutation_Response>;
  /** delete single row from the table: "users_favorite" */
  delete_users_favorite_by_pk?: Maybe<Users_Favorite>;
  /** delete data from the table: "users_history" */
  delete_users_history?: Maybe<Users_History_Mutation_Response>;
  /** delete single row from the table: "users_history" */
  delete_users_history_by_pk?: Maybe<Users_History>;
  /** insert data into the table: "case_law_ids_nsoud" */
  insert_case_law_ids_nsoud?: Maybe<Case_Law_Ids_Nsoud_Mutation_Response>;
  /** insert a single row into the table: "case_law_ids_nsoud" */
  insert_case_law_ids_nsoud_one?: Maybe<Case_Law_Ids_Nsoud>;
  /** insert data into the table: "case_law_ids_nssoud" */
  insert_case_law_ids_nssoud?: Maybe<Case_Law_Ids_Nssoud_Mutation_Response>;
  /** insert a single row into the table: "case_law_ids_nssoud" */
  insert_case_law_ids_nssoud_one?: Maybe<Case_Law_Ids_Nssoud>;
  /** insert data into the table: "case_law_ids_usoud" */
  insert_case_law_ids_usoud?: Maybe<Case_Law_Ids_Usoud_Mutation_Response>;
  /** insert a single row into the table: "case_law_ids_usoud" */
  insert_case_law_ids_usoud_one?: Maybe<Case_Law_Ids_Usoud>;
  /** insert data into the table: "email_verification_requests" */
  insert_email_verification_requests?: Maybe<Email_Verification_Requests_Mutation_Response>;
  /** insert a single row into the table: "email_verification_requests" */
  insert_email_verification_requests_one?: Maybe<Email_Verification_Requests>;
  /** insert data into the table: "legislation_ids" */
  insert_legislation_ids?: Maybe<Legislation_Ids_Mutation_Response>;
  /** insert a single row into the table: "legislation_ids" */
  insert_legislation_ids_one?: Maybe<Legislation_Ids>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "password_reset_requests" */
  insert_password_reset_requests?: Maybe<Password_Reset_Requests_Mutation_Response>;
  /** insert a single row into the table: "password_reset_requests" */
  insert_password_reset_requests_one?: Maybe<Password_Reset_Requests>;
  /** insert data into the table: "raw_case_law_nsoud" */
  insert_raw_case_law_nsoud?: Maybe<Raw_Case_Law_Nsoud_Mutation_Response>;
  /** insert a single row into the table: "raw_case_law_nsoud" */
  insert_raw_case_law_nsoud_one?: Maybe<Raw_Case_Law_Nsoud>;
  /** insert data into the table: "raw_case_law_nssoud" */
  insert_raw_case_law_nssoud?: Maybe<Raw_Case_Law_Nssoud_Mutation_Response>;
  /** insert a single row into the table: "raw_case_law_nssoud" */
  insert_raw_case_law_nssoud_one?: Maybe<Raw_Case_Law_Nssoud>;
  /** insert data into the table: "raw_case_law_usoud" */
  insert_raw_case_law_usoud?: Maybe<Raw_Case_Law_Usoud_Mutation_Response>;
  /** insert a single row into the table: "raw_case_law_usoud" */
  insert_raw_case_law_usoud_one?: Maybe<Raw_Case_Law_Usoud>;
  /** insert data into the table: "raw_legislation" */
  insert_raw_legislation?: Maybe<Raw_Legislation_Mutation_Response>;
  /** insert a single row into the table: "raw_legislation" */
  insert_raw_legislation_one?: Maybe<Raw_Legislation>;
  /** insert data into the table: "research" */
  insert_research?: Maybe<Research_Mutation_Response>;
  /** insert a single row into the table: "research" */
  insert_research_one?: Maybe<Research>;
  /** insert data into the table: "scraper_state" */
  insert_scraper_state?: Maybe<Scraper_State_Mutation_Response>;
  /** insert a single row into the table: "scraper_state" */
  insert_scraper_state_one?: Maybe<Scraper_State>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_favorite" */
  insert_users_favorite?: Maybe<Users_Favorite_Mutation_Response>;
  /** insert a single row into the table: "users_favorite" */
  insert_users_favorite_one?: Maybe<Users_Favorite>;
  /** insert data into the table: "users_history" */
  insert_users_history?: Maybe<Users_History_Mutation_Response>;
  /** insert a single row into the table: "users_history" */
  insert_users_history_one?: Maybe<Users_History>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  invite_user: Scalars["Boolean"]["output"];
  perform_email_verification: VerifyEmailResponse;
  perform_password_reset: TokenResponse;
  request_email_verification: Scalars["Boolean"]["output"];
  request_password_reset: Scalars["String"]["output"];
  sign_in: TokenResponse;
  sign_up: Scalars["String"]["output"];
  /** update data of the table: "case_law_ids_nsoud" */
  update_case_law_ids_nsoud?: Maybe<Case_Law_Ids_Nsoud_Mutation_Response>;
  /** update single row of the table: "case_law_ids_nsoud" */
  update_case_law_ids_nsoud_by_pk?: Maybe<Case_Law_Ids_Nsoud>;
  /** update multiples rows of table: "case_law_ids_nsoud" */
  update_case_law_ids_nsoud_many?: Maybe<Array<Maybe<Case_Law_Ids_Nsoud_Mutation_Response>>>;
  /** update data of the table: "case_law_ids_nssoud" */
  update_case_law_ids_nssoud?: Maybe<Case_Law_Ids_Nssoud_Mutation_Response>;
  /** update single row of the table: "case_law_ids_nssoud" */
  update_case_law_ids_nssoud_by_pk?: Maybe<Case_Law_Ids_Nssoud>;
  /** update multiples rows of table: "case_law_ids_nssoud" */
  update_case_law_ids_nssoud_many?: Maybe<Array<Maybe<Case_Law_Ids_Nssoud_Mutation_Response>>>;
  /** update data of the table: "case_law_ids_usoud" */
  update_case_law_ids_usoud?: Maybe<Case_Law_Ids_Usoud_Mutation_Response>;
  /** update single row of the table: "case_law_ids_usoud" */
  update_case_law_ids_usoud_by_pk?: Maybe<Case_Law_Ids_Usoud>;
  /** update multiples rows of table: "case_law_ids_usoud" */
  update_case_law_ids_usoud_many?: Maybe<Array<Maybe<Case_Law_Ids_Usoud_Mutation_Response>>>;
  /** update data of the table: "email_verification_requests" */
  update_email_verification_requests?: Maybe<Email_Verification_Requests_Mutation_Response>;
  /** update single row of the table: "email_verification_requests" */
  update_email_verification_requests_by_pk?: Maybe<Email_Verification_Requests>;
  /** update multiples rows of table: "email_verification_requests" */
  update_email_verification_requests_many?: Maybe<Array<Maybe<Email_Verification_Requests_Mutation_Response>>>;
  /** update data of the table: "legislation_ids" */
  update_legislation_ids?: Maybe<Legislation_Ids_Mutation_Response>;
  /** update single row of the table: "legislation_ids" */
  update_legislation_ids_by_pk?: Maybe<Legislation_Ids>;
  /** update multiples rows of table: "legislation_ids" */
  update_legislation_ids_many?: Maybe<Array<Maybe<Legislation_Ids_Mutation_Response>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<Organizations_Mutation_Response>>>;
  /** update data of the table: "password_reset_requests" */
  update_password_reset_requests?: Maybe<Password_Reset_Requests_Mutation_Response>;
  /** update single row of the table: "password_reset_requests" */
  update_password_reset_requests_by_pk?: Maybe<Password_Reset_Requests>;
  /** update multiples rows of table: "password_reset_requests" */
  update_password_reset_requests_many?: Maybe<Array<Maybe<Password_Reset_Requests_Mutation_Response>>>;
  /** update data of the table: "raw_case_law_nsoud" */
  update_raw_case_law_nsoud?: Maybe<Raw_Case_Law_Nsoud_Mutation_Response>;
  /** update single row of the table: "raw_case_law_nsoud" */
  update_raw_case_law_nsoud_by_pk?: Maybe<Raw_Case_Law_Nsoud>;
  /** update multiples rows of table: "raw_case_law_nsoud" */
  update_raw_case_law_nsoud_many?: Maybe<Array<Maybe<Raw_Case_Law_Nsoud_Mutation_Response>>>;
  /** update data of the table: "raw_case_law_nssoud" */
  update_raw_case_law_nssoud?: Maybe<Raw_Case_Law_Nssoud_Mutation_Response>;
  /** update single row of the table: "raw_case_law_nssoud" */
  update_raw_case_law_nssoud_by_pk?: Maybe<Raw_Case_Law_Nssoud>;
  /** update multiples rows of table: "raw_case_law_nssoud" */
  update_raw_case_law_nssoud_many?: Maybe<Array<Maybe<Raw_Case_Law_Nssoud_Mutation_Response>>>;
  /** update data of the table: "raw_case_law_usoud" */
  update_raw_case_law_usoud?: Maybe<Raw_Case_Law_Usoud_Mutation_Response>;
  /** update single row of the table: "raw_case_law_usoud" */
  update_raw_case_law_usoud_by_pk?: Maybe<Raw_Case_Law_Usoud>;
  /** update multiples rows of table: "raw_case_law_usoud" */
  update_raw_case_law_usoud_many?: Maybe<Array<Maybe<Raw_Case_Law_Usoud_Mutation_Response>>>;
  /** update data of the table: "raw_legislation" */
  update_raw_legislation?: Maybe<Raw_Legislation_Mutation_Response>;
  /** update single row of the table: "raw_legislation" */
  update_raw_legislation_by_pk?: Maybe<Raw_Legislation>;
  /** update multiples rows of table: "raw_legislation" */
  update_raw_legislation_many?: Maybe<Array<Maybe<Raw_Legislation_Mutation_Response>>>;
  /** update data of the table: "research" */
  update_research?: Maybe<Research_Mutation_Response>;
  /** update single row of the table: "research" */
  update_research_by_pk?: Maybe<Research>;
  /** update multiples rows of table: "research" */
  update_research_many?: Maybe<Array<Maybe<Research_Mutation_Response>>>;
  /** update data of the table: "scraper_state" */
  update_scraper_state?: Maybe<Scraper_State_Mutation_Response>;
  /** update single row of the table: "scraper_state" */
  update_scraper_state_by_pk?: Maybe<Scraper_State>;
  /** update multiples rows of table: "scraper_state" */
  update_scraper_state_many?: Maybe<Array<Maybe<Scraper_State_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_favorite" */
  update_users_favorite?: Maybe<Users_Favorite_Mutation_Response>;
  /** update single row of the table: "users_favorite" */
  update_users_favorite_by_pk?: Maybe<Users_Favorite>;
  /** update multiples rows of table: "users_favorite" */
  update_users_favorite_many?: Maybe<Array<Maybe<Users_Favorite_Mutation_Response>>>;
  /** update data of the table: "users_history" */
  update_users_history?: Maybe<Users_History_Mutation_Response>;
  /** update single row of the table: "users_history" */
  update_users_history_by_pk?: Maybe<Users_History>;
  /** update multiples rows of table: "users_history" */
  update_users_history_many?: Maybe<Array<Maybe<Users_History_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootActivate_AccountArgs = {
  company_size: Scalars["String"]["input"];
  job: Scalars["String"]["input"];
  law_areas: Scalars["String"]["input"];
  request_id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootChange_PasswordArgs = {
  new_password: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_NsoudArgs = {
  where: Case_Law_Ids_Nsoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_NssoudArgs = {
  where: Case_Law_Ids_Nssoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_UsoudArgs = {
  where: Case_Law_Ids_Usoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Law_Ids_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Email_Verification_RequestsArgs = {
  where: Email_Verification_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Verification_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Legislation_IdsArgs = {
  where: Legislation_Ids_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Legislation_Ids_By_PkArgs = {
  id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Password_Reset_RequestsArgs = {
  where: Password_Reset_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Password_Reset_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_NsoudArgs = {
  where: Raw_Case_Law_Nsoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_NssoudArgs = {
  where: Raw_Case_Law_Nssoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_UsoudArgs = {
  where: Raw_Case_Law_Usoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Raw_Case_Law_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Raw_LegislationArgs = {
  where: Raw_Legislation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Raw_Legislation_By_PkArgs = {
  id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_ResearchArgs = {
  where: Research_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Research_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Scraper_StateArgs = {
  where: Scraper_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Scraper_State_By_PkArgs = {
  key: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Users_FavoriteArgs = {
  where: Users_Favorite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_Favorite_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootDelete_Users_HistoryArgs = {
  where: Users_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_History_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_NsoudArgs = {
  objects: Array<Case_Law_Ids_Nsoud_Insert_Input>;
  on_conflict?: InputMaybe<Case_Law_Ids_Nsoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_Nsoud_OneArgs = {
  object: Case_Law_Ids_Nsoud_Insert_Input;
  on_conflict?: InputMaybe<Case_Law_Ids_Nsoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_NssoudArgs = {
  objects: Array<Case_Law_Ids_Nssoud_Insert_Input>;
  on_conflict?: InputMaybe<Case_Law_Ids_Nssoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_Nssoud_OneArgs = {
  object: Case_Law_Ids_Nssoud_Insert_Input;
  on_conflict?: InputMaybe<Case_Law_Ids_Nssoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_UsoudArgs = {
  objects: Array<Case_Law_Ids_Usoud_Insert_Input>;
  on_conflict?: InputMaybe<Case_Law_Ids_Usoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Law_Ids_Usoud_OneArgs = {
  object: Case_Law_Ids_Usoud_Insert_Input;
  on_conflict?: InputMaybe<Case_Law_Ids_Usoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Verification_RequestsArgs = {
  objects: Array<Email_Verification_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Email_Verification_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Verification_Requests_OneArgs = {
  object: Email_Verification_Requests_Insert_Input;
  on_conflict?: InputMaybe<Email_Verification_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Legislation_IdsArgs = {
  objects: Array<Legislation_Ids_Insert_Input>;
  on_conflict?: InputMaybe<Legislation_Ids_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Legislation_Ids_OneArgs = {
  object: Legislation_Ids_Insert_Input;
  on_conflict?: InputMaybe<Legislation_Ids_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Password_Reset_RequestsArgs = {
  objects: Array<Password_Reset_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Password_Reset_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Password_Reset_Requests_OneArgs = {
  object: Password_Reset_Requests_Insert_Input;
  on_conflict?: InputMaybe<Password_Reset_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_NsoudArgs = {
  objects: Array<Raw_Case_Law_Nsoud_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Case_Law_Nsoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_Nsoud_OneArgs = {
  object: Raw_Case_Law_Nsoud_Insert_Input;
  on_conflict?: InputMaybe<Raw_Case_Law_Nsoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_NssoudArgs = {
  objects: Array<Raw_Case_Law_Nssoud_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Case_Law_Nssoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_Nssoud_OneArgs = {
  object: Raw_Case_Law_Nssoud_Insert_Input;
  on_conflict?: InputMaybe<Raw_Case_Law_Nssoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_UsoudArgs = {
  objects: Array<Raw_Case_Law_Usoud_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Case_Law_Usoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Case_Law_Usoud_OneArgs = {
  object: Raw_Case_Law_Usoud_Insert_Input;
  on_conflict?: InputMaybe<Raw_Case_Law_Usoud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_LegislationArgs = {
  objects: Array<Raw_Legislation_Insert_Input>;
  on_conflict?: InputMaybe<Raw_Legislation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Raw_Legislation_OneArgs = {
  object: Raw_Legislation_Insert_Input;
  on_conflict?: InputMaybe<Raw_Legislation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ResearchArgs = {
  objects: Array<Research_Insert_Input>;
  on_conflict?: InputMaybe<Research_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Research_OneArgs = {
  object: Research_Insert_Input;
  on_conflict?: InputMaybe<Research_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Scraper_StateArgs = {
  objects: Array<Scraper_State_Insert_Input>;
  on_conflict?: InputMaybe<Scraper_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Scraper_State_OneArgs = {
  object: Scraper_State_Insert_Input;
  on_conflict?: InputMaybe<Scraper_State_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_FavoriteArgs = {
  objects: Array<Users_Favorite_Insert_Input>;
  on_conflict?: InputMaybe<Users_Favorite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_Favorite_OneArgs = {
  object: Users_Favorite_Insert_Input;
  on_conflict?: InputMaybe<Users_Favorite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_HistoryArgs = {
  objects: Array<Users_History_Insert_Input>;
  on_conflict?: InputMaybe<Users_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_History_OneArgs = {
  object: Users_History_Insert_Input;
  on_conflict?: InputMaybe<Users_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInvite_UserArgs = {
  email: Scalars["String"]["input"];
  first_name: Scalars["String"]["input"];
  last_name: Scalars["String"]["input"];
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
  pricing?: InputMaybe<Scalars["String"]["input"]>;
};

/** mutation root */
export type Mutation_RootPerform_Email_VerificationArgs = {
  request_id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootPerform_Password_ResetArgs = {
  new_password: Scalars["String"]["input"];
  request_id: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootRequest_Email_VerificationArgs = {
  email: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootRequest_Password_ResetArgs = {
  email: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootSign_InArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootSign_UpArgs = {
  email: Scalars["String"]["input"];
  first_name: Scalars["String"]["input"];
  last_name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_NsoudArgs = {
  _set?: InputMaybe<Case_Law_Ids_Nsoud_Set_Input>;
  where: Case_Law_Ids_Nsoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Nsoud_By_PkArgs = {
  _set?: InputMaybe<Case_Law_Ids_Nsoud_Set_Input>;
  pk_columns: Case_Law_Ids_Nsoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Nsoud_ManyArgs = {
  updates: Array<Case_Law_Ids_Nsoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_NssoudArgs = {
  _inc?: InputMaybe<Case_Law_Ids_Nssoud_Inc_Input>;
  _set?: InputMaybe<Case_Law_Ids_Nssoud_Set_Input>;
  where: Case_Law_Ids_Nssoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Nssoud_By_PkArgs = {
  _inc?: InputMaybe<Case_Law_Ids_Nssoud_Inc_Input>;
  _set?: InputMaybe<Case_Law_Ids_Nssoud_Set_Input>;
  pk_columns: Case_Law_Ids_Nssoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Nssoud_ManyArgs = {
  updates: Array<Case_Law_Ids_Nssoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_UsoudArgs = {
  _inc?: InputMaybe<Case_Law_Ids_Usoud_Inc_Input>;
  _set?: InputMaybe<Case_Law_Ids_Usoud_Set_Input>;
  where: Case_Law_Ids_Usoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Usoud_By_PkArgs = {
  _inc?: InputMaybe<Case_Law_Ids_Usoud_Inc_Input>;
  _set?: InputMaybe<Case_Law_Ids_Usoud_Set_Input>;
  pk_columns: Case_Law_Ids_Usoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Law_Ids_Usoud_ManyArgs = {
  updates: Array<Case_Law_Ids_Usoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Verification_RequestsArgs = {
  _set?: InputMaybe<Email_Verification_Requests_Set_Input>;
  where: Email_Verification_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Verification_Requests_By_PkArgs = {
  _set?: InputMaybe<Email_Verification_Requests_Set_Input>;
  pk_columns: Email_Verification_Requests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Verification_Requests_ManyArgs = {
  updates: Array<Email_Verification_Requests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Legislation_IdsArgs = {
  _set?: InputMaybe<Legislation_Ids_Set_Input>;
  where: Legislation_Ids_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Legislation_Ids_By_PkArgs = {
  _set?: InputMaybe<Legislation_Ids_Set_Input>;
  pk_columns: Legislation_Ids_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Legislation_Ids_ManyArgs = {
  updates: Array<Legislation_Ids_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organizations_ManyArgs = {
  updates: Array<Organizations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Password_Reset_RequestsArgs = {
  _set?: InputMaybe<Password_Reset_Requests_Set_Input>;
  where: Password_Reset_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Requests_By_PkArgs = {
  _set?: InputMaybe<Password_Reset_Requests_Set_Input>;
  pk_columns: Password_Reset_Requests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Requests_ManyArgs = {
  updates: Array<Password_Reset_Requests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_NsoudArgs = {
  _set?: InputMaybe<Raw_Case_Law_Nsoud_Set_Input>;
  where: Raw_Case_Law_Nsoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Nsoud_By_PkArgs = {
  _set?: InputMaybe<Raw_Case_Law_Nsoud_Set_Input>;
  pk_columns: Raw_Case_Law_Nsoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Nsoud_ManyArgs = {
  updates: Array<Raw_Case_Law_Nsoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_NssoudArgs = {
  _inc?: InputMaybe<Raw_Case_Law_Nssoud_Inc_Input>;
  _set?: InputMaybe<Raw_Case_Law_Nssoud_Set_Input>;
  where: Raw_Case_Law_Nssoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Nssoud_By_PkArgs = {
  _inc?: InputMaybe<Raw_Case_Law_Nssoud_Inc_Input>;
  _set?: InputMaybe<Raw_Case_Law_Nssoud_Set_Input>;
  pk_columns: Raw_Case_Law_Nssoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Nssoud_ManyArgs = {
  updates: Array<Raw_Case_Law_Nssoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_UsoudArgs = {
  _inc?: InputMaybe<Raw_Case_Law_Usoud_Inc_Input>;
  _set?: InputMaybe<Raw_Case_Law_Usoud_Set_Input>;
  where: Raw_Case_Law_Usoud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Usoud_By_PkArgs = {
  _inc?: InputMaybe<Raw_Case_Law_Usoud_Inc_Input>;
  _set?: InputMaybe<Raw_Case_Law_Usoud_Set_Input>;
  pk_columns: Raw_Case_Law_Usoud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Case_Law_Usoud_ManyArgs = {
  updates: Array<Raw_Case_Law_Usoud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_LegislationArgs = {
  _set?: InputMaybe<Raw_Legislation_Set_Input>;
  where: Raw_Legislation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Legislation_By_PkArgs = {
  _set?: InputMaybe<Raw_Legislation_Set_Input>;
  pk_columns: Raw_Legislation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Raw_Legislation_ManyArgs = {
  updates: Array<Raw_Legislation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ResearchArgs = {
  _append?: InputMaybe<Research_Append_Input>;
  _delete_at_path?: InputMaybe<Research_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Research_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Research_Delete_Key_Input>;
  _prepend?: InputMaybe<Research_Prepend_Input>;
  _set?: InputMaybe<Research_Set_Input>;
  where: Research_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Research_By_PkArgs = {
  _append?: InputMaybe<Research_Append_Input>;
  _delete_at_path?: InputMaybe<Research_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Research_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Research_Delete_Key_Input>;
  _prepend?: InputMaybe<Research_Prepend_Input>;
  _set?: InputMaybe<Research_Set_Input>;
  pk_columns: Research_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Research_ManyArgs = {
  updates: Array<Research_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Scraper_StateArgs = {
  _set?: InputMaybe<Scraper_State_Set_Input>;
  where: Scraper_State_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Scraper_State_By_PkArgs = {
  _set?: InputMaybe<Scraper_State_Set_Input>;
  pk_columns: Scraper_State_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Scraper_State_ManyArgs = {
  updates: Array<Scraper_State_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Inc_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_FavoriteArgs = {
  _set?: InputMaybe<Users_Favorite_Set_Input>;
  where: Users_Favorite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Favorite_By_PkArgs = {
  _set?: InputMaybe<Users_Favorite_Set_Input>;
  pk_columns: Users_Favorite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Favorite_ManyArgs = {
  updates: Array<Users_Favorite_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_HistoryArgs = {
  _set?: InputMaybe<Users_History_Set_Input>;
  where: Users_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_History_By_PkArgs = {
  _set?: InputMaybe<Users_History_Set_Input>;
  pk_columns: Users_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_History_ManyArgs = {
  updates: Array<Users_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** columns and relationships of "my_user" */
export type My_User = {
  __typename?: "my_user";
  acknowledged_not_law_advice?: Maybe<Scalars["Boolean"]["output"]>;
  acknowledged_not_law_advice_documents?: Maybe<Scalars["Boolean"]["output"]>;
  added_law_to_favorite?: Maybe<Scalars["Boolean"]["output"]>;
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  clicked_follow_up_question?: Maybe<Scalars["Boolean"]["output"]>;
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  created_research?: Maybe<Scalars["Boolean"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  last_name?: Maybe<Scalars["String"]["output"]>;
  law_areas?: Maybe<Scalars["jsonb"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  organization_admin?: Maybe<Scalars["Boolean"]["output"]>;
  organization_expiration?: Maybe<Scalars["timestamptz"]["output"]>;
  organization_ico?: Maybe<Scalars["String"]["output"]>;
  organization_name?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Scalars["String"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
  searched_law?: Maybe<Scalars["Boolean"]["output"]>;
  super_admin?: Maybe<Scalars["Boolean"]["output"]>;
  tel?: Maybe<Scalars["String"]["output"]>;
  tried_chat_with_document?: Maybe<Scalars["Boolean"]["output"]>;
  tried_chat_with_research?: Maybe<Scalars["Boolean"]["output"]>;
  tried_research_settings?: Maybe<Scalars["Boolean"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  uploaded_document?: Maybe<Scalars["Boolean"]["output"]>;
  used_discount?: Maybe<Scalars["Boolean"]["output"]>;
  viewed_new_features?: Maybe<Scalars["Boolean"]["output"]>;
  viewed_onboarding?: Maybe<Scalars["Boolean"]["output"]>;
};

/** columns and relationships of "my_user" */
export type My_UserLaw_AreasArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "my_user" */
export type My_User_Aggregate = {
  __typename?: "my_user_aggregate";
  aggregate?: Maybe<My_User_Aggregate_Fields>;
  nodes: Array<My_User>;
};

/** aggregate fields of "my_user" */
export type My_User_Aggregate_Fields = {
  __typename?: "my_user_aggregate_fields";
  avg?: Maybe<My_User_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<My_User_Max_Fields>;
  min?: Maybe<My_User_Min_Fields>;
  stddev?: Maybe<My_User_Stddev_Fields>;
  stddev_pop?: Maybe<My_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<My_User_Stddev_Samp_Fields>;
  sum?: Maybe<My_User_Sum_Fields>;
  var_pop?: Maybe<My_User_Var_Pop_Fields>;
  var_samp?: Maybe<My_User_Var_Samp_Fields>;
  variance?: Maybe<My_User_Variance_Fields>;
};

/** aggregate fields of "my_user" */
export type My_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<My_User_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type My_User_Avg_Fields = {
  __typename?: "my_user_avg_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "my_user". All fields are combined with a logical 'AND'. */
export type My_User_Bool_Exp = {
  _and?: InputMaybe<Array<My_User_Bool_Exp>>;
  _not?: InputMaybe<My_User_Bool_Exp>;
  _or?: InputMaybe<Array<My_User_Bool_Exp>>;
  acknowledged_not_law_advice?: InputMaybe<Boolean_Comparison_Exp>;
  acknowledged_not_law_advice_documents?: InputMaybe<Boolean_Comparison_Exp>;
  added_law_to_favorite?: InputMaybe<Boolean_Comparison_Exp>;
  billing_id?: InputMaybe<String_Comparison_Exp>;
  billing_period?: InputMaybe<String_Comparison_Exp>;
  chat_generations?: InputMaybe<Int_Comparison_Exp>;
  clicked_follow_up_question?: InputMaybe<Boolean_Comparison_Exp>;
  company_size?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_research?: InputMaybe<Boolean_Comparison_Exp>;
  documents_uploaded?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  law_areas?: InputMaybe<Jsonb_Comparison_Exp>;
  organization?: InputMaybe<Uuid_Comparison_Exp>;
  organization_admin?: InputMaybe<Boolean_Comparison_Exp>;
  organization_expiration?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization_ico?: InputMaybe<String_Comparison_Exp>;
  organization_name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  pricing?: InputMaybe<String_Comparison_Exp>;
  research_generations?: InputMaybe<Int_Comparison_Exp>;
  searched_law?: InputMaybe<Boolean_Comparison_Exp>;
  super_admin?: InputMaybe<Boolean_Comparison_Exp>;
  tel?: InputMaybe<String_Comparison_Exp>;
  tried_chat_with_document?: InputMaybe<Boolean_Comparison_Exp>;
  tried_chat_with_research?: InputMaybe<Boolean_Comparison_Exp>;
  tried_research_settings?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_document?: InputMaybe<Boolean_Comparison_Exp>;
  used_discount?: InputMaybe<Boolean_Comparison_Exp>;
  viewed_new_features?: InputMaybe<Boolean_Comparison_Exp>;
  viewed_onboarding?: InputMaybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type My_User_Max_Fields = {
  __typename?: "my_user_max_fields";
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  last_name?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  organization_expiration?: Maybe<Scalars["timestamptz"]["output"]>;
  organization_ico?: Maybe<Scalars["String"]["output"]>;
  organization_name?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Scalars["String"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
  tel?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type My_User_Min_Fields = {
  __typename?: "my_user_min_fields";
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  last_name?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  organization_expiration?: Maybe<Scalars["timestamptz"]["output"]>;
  organization_ico?: Maybe<Scalars["String"]["output"]>;
  organization_name?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Scalars["String"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
  tel?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** Ordering options when selecting data from "my_user". */
export type My_User_Order_By = {
  acknowledged_not_law_advice?: InputMaybe<Order_By>;
  acknowledged_not_law_advice_documents?: InputMaybe<Order_By>;
  added_law_to_favorite?: InputMaybe<Order_By>;
  billing_id?: InputMaybe<Order_By>;
  billing_period?: InputMaybe<Order_By>;
  chat_generations?: InputMaybe<Order_By>;
  clicked_follow_up_question?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_research?: InputMaybe<Order_By>;
  documents_uploaded?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  law_areas?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  organization_admin?: InputMaybe<Order_By>;
  organization_expiration?: InputMaybe<Order_By>;
  organization_ico?: InputMaybe<Order_By>;
  organization_name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  pricing?: InputMaybe<Order_By>;
  research_generations?: InputMaybe<Order_By>;
  searched_law?: InputMaybe<Order_By>;
  super_admin?: InputMaybe<Order_By>;
  tel?: InputMaybe<Order_By>;
  tried_chat_with_document?: InputMaybe<Order_By>;
  tried_chat_with_research?: InputMaybe<Order_By>;
  tried_research_settings?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_document?: InputMaybe<Order_By>;
  used_discount?: InputMaybe<Order_By>;
  viewed_new_features?: InputMaybe<Order_By>;
  viewed_onboarding?: InputMaybe<Order_By>;
};

/** select columns of table "my_user" */
export enum My_User_Select_Column {
  /** column name */
  AcknowledgedNotLawAdvice = "acknowledged_not_law_advice",
  /** column name */
  AcknowledgedNotLawAdviceDocuments = "acknowledged_not_law_advice_documents",
  /** column name */
  AddedLawToFavorite = "added_law_to_favorite",
  /** column name */
  BillingId = "billing_id",
  /** column name */
  BillingPeriod = "billing_period",
  /** column name */
  ChatGenerations = "chat_generations",
  /** column name */
  ClickedFollowUpQuestion = "clicked_follow_up_question",
  /** column name */
  CompanySize = "company_size",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedResearch = "created_research",
  /** column name */
  DocumentsUploaded = "documents_uploaded",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  Job = "job",
  /** column name */
  LastName = "last_name",
  /** column name */
  LawAreas = "law_areas",
  /** column name */
  Organization = "organization",
  /** column name */
  OrganizationAdmin = "organization_admin",
  /** column name */
  OrganizationExpiration = "organization_expiration",
  /** column name */
  OrganizationIco = "organization_ico",
  /** column name */
  OrganizationName = "organization_name",
  /** column name */
  Password = "password",
  /** column name */
  Pricing = "pricing",
  /** column name */
  ResearchGenerations = "research_generations",
  /** column name */
  SearchedLaw = "searched_law",
  /** column name */
  SuperAdmin = "super_admin",
  /** column name */
  Tel = "tel",
  /** column name */
  TriedChatWithDocument = "tried_chat_with_document",
  /** column name */
  TriedChatWithResearch = "tried_chat_with_research",
  /** column name */
  TriedResearchSettings = "tried_research_settings",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UploadedDocument = "uploaded_document",
  /** column name */
  UsedDiscount = "used_discount",
  /** column name */
  ViewedNewFeatures = "viewed_new_features",
  /** column name */
  ViewedOnboarding = "viewed_onboarding",
}

/** aggregate stddev on columns */
export type My_User_Stddev_Fields = {
  __typename?: "my_user_stddev_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type My_User_Stddev_Pop_Fields = {
  __typename?: "my_user_stddev_pop_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type My_User_Stddev_Samp_Fields = {
  __typename?: "my_user_stddev_samp_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "my_user" */
export type My_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: My_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type My_User_Stream_Cursor_Value_Input = {
  acknowledged_not_law_advice?: InputMaybe<Scalars["Boolean"]["input"]>;
  acknowledged_not_law_advice_documents?: InputMaybe<Scalars["Boolean"]["input"]>;
  added_law_to_favorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  billing_id?: InputMaybe<Scalars["String"]["input"]>;
  billing_period?: InputMaybe<Scalars["String"]["input"]>;
  chat_generations?: InputMaybe<Scalars["Int"]["input"]>;
  clicked_follow_up_question?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_size?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  documents_uploaded?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
  organization?: InputMaybe<Scalars["uuid"]["input"]>;
  organization_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  organization_expiration?: InputMaybe<Scalars["timestamptz"]["input"]>;
  organization_ico?: InputMaybe<Scalars["String"]["input"]>;
  organization_name?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  pricing?: InputMaybe<Scalars["String"]["input"]>;
  research_generations?: InputMaybe<Scalars["Int"]["input"]>;
  searched_law?: InputMaybe<Scalars["Boolean"]["input"]>;
  super_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  tel?: InputMaybe<Scalars["String"]["input"]>;
  tried_chat_with_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_chat_with_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_research_settings?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uploaded_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  used_discount?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_new_features?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_onboarding?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type My_User_Sum_Fields = {
  __typename?: "my_user_sum_fields";
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
};

/** aggregate var_pop on columns */
export type My_User_Var_Pop_Fields = {
  __typename?: "my_user_var_pop_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type My_User_Var_Samp_Fields = {
  __typename?: "my_user_var_samp_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type My_User_Variance_Fields = {
  __typename?: "my_user_variance_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: "organizations";
  city?: Maybe<Scalars["String"]["output"]>;
  communication_email?: Maybe<Scalars["String"]["output"]>;
  communication_first_name?: Maybe<Scalars["String"]["output"]>;
  communication_last_name?: Maybe<Scalars["String"]["output"]>;
  communication_tel?: Maybe<Scalars["String"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  dic?: Maybe<Scalars["String"]["output"]>;
  expiration: Scalars["timestamptz"]["output"];
  ico: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  invoice_email?: Maybe<Scalars["String"]["output"]>;
  is_communication_email_same_as_invoice_email: Scalars["Boolean"]["output"];
  postal_code?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: "organizations_aggregate";
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

export type Organizations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organizations_Aggregate_Bool_Exp_Count>;
};

export type Organizations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: "organizations_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  communication_email?: InputMaybe<String_Comparison_Exp>;
  communication_first_name?: InputMaybe<String_Comparison_Exp>;
  communication_last_name?: InputMaybe<String_Comparison_Exp>;
  communication_tel?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dic?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Timestamptz_Comparison_Exp>;
  ico?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_email?: InputMaybe<String_Comparison_Exp>;
  is_communication_email_same_as_invoice_email?: InputMaybe<Boolean_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = "organizations_pkey",
}

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  communication_email?: InputMaybe<Scalars["String"]["input"]>;
  communication_first_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_last_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_tel?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dic?: InputMaybe<Scalars["String"]["input"]>;
  expiration?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ico?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  invoice_email?: InputMaybe<Scalars["String"]["input"]>;
  is_communication_email_same_as_invoice_email?: InputMaybe<Scalars["Boolean"]["input"]>;
  postal_code?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: "organizations_max_fields";
  city?: Maybe<Scalars["String"]["output"]>;
  communication_email?: Maybe<Scalars["String"]["output"]>;
  communication_first_name?: Maybe<Scalars["String"]["output"]>;
  communication_last_name?: Maybe<Scalars["String"]["output"]>;
  communication_tel?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  dic?: Maybe<Scalars["String"]["output"]>;
  expiration?: Maybe<Scalars["timestamptz"]["output"]>;
  ico?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  invoice_email?: Maybe<Scalars["String"]["output"]>;
  postal_code?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  city?: InputMaybe<Order_By>;
  communication_email?: InputMaybe<Order_By>;
  communication_first_name?: InputMaybe<Order_By>;
  communication_last_name?: InputMaybe<Order_By>;
  communication_tel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dic?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  ico?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_email?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: "organizations_min_fields";
  city?: Maybe<Scalars["String"]["output"]>;
  communication_email?: Maybe<Scalars["String"]["output"]>;
  communication_first_name?: Maybe<Scalars["String"]["output"]>;
  communication_last_name?: Maybe<Scalars["String"]["output"]>;
  communication_tel?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  dic?: Maybe<Scalars["String"]["output"]>;
  expiration?: Maybe<Scalars["timestamptz"]["output"]>;
  ico?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  invoice_email?: Maybe<Scalars["String"]["output"]>;
  postal_code?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  city?: InputMaybe<Order_By>;
  communication_email?: InputMaybe<Order_By>;
  communication_first_name?: InputMaybe<Order_By>;
  communication_last_name?: InputMaybe<Order_By>;
  communication_tel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dic?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  ico?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_email?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: "organizations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  city?: InputMaybe<Order_By>;
  communication_email?: InputMaybe<Order_By>;
  communication_first_name?: InputMaybe<Order_By>;
  communication_last_name?: InputMaybe<Order_By>;
  communication_tel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dic?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  ico?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_email?: InputMaybe<Order_By>;
  is_communication_email_same_as_invoice_email?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  City = "city",
  /** column name */
  CommunicationEmail = "communication_email",
  /** column name */
  CommunicationFirstName = "communication_first_name",
  /** column name */
  CommunicationLastName = "communication_last_name",
  /** column name */
  CommunicationTel = "communication_tel",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dic = "dic",
  /** column name */
  Expiration = "expiration",
  /** column name */
  Ico = "ico",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceEmail = "invoice_email",
  /** column name */
  IsCommunicationEmailSameAsInvoiceEmail = "is_communication_email_same_as_invoice_email",
  /** column name */
  PostalCode = "postal_code",
  /** column name */
  State = "state",
  /** column name */
  Street = "street",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "organizations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCommunicationEmailSameAsInvoiceEmail = "is_communication_email_same_as_invoice_email",
}

/** select "organizations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCommunicationEmailSameAsInvoiceEmail = "is_communication_email_same_as_invoice_email",
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  communication_email?: InputMaybe<Scalars["String"]["input"]>;
  communication_first_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_last_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_tel?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dic?: InputMaybe<Scalars["String"]["input"]>;
  expiration?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ico?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  invoice_email?: InputMaybe<Scalars["String"]["input"]>;
  is_communication_email_same_as_invoice_email?: InputMaybe<Scalars["Boolean"]["input"]>;
  postal_code?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** Streaming cursor of the table "organizations" */
export type Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organizations_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  communication_email?: InputMaybe<Scalars["String"]["input"]>;
  communication_first_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_last_name?: InputMaybe<Scalars["String"]["input"]>;
  communication_tel?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  dic?: InputMaybe<Scalars["String"]["input"]>;
  expiration?: InputMaybe<Scalars["timestamptz"]["input"]>;
  ico?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  invoice_email?: InputMaybe<Scalars["String"]["input"]>;
  is_communication_email_same_as_invoice_email?: InputMaybe<Scalars["Boolean"]["input"]>;
  postal_code?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  City = "city",
  /** column name */
  CommunicationEmail = "communication_email",
  /** column name */
  CommunicationFirstName = "communication_first_name",
  /** column name */
  CommunicationLastName = "communication_last_name",
  /** column name */
  CommunicationTel = "communication_tel",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dic = "dic",
  /** column name */
  Expiration = "expiration",
  /** column name */
  Ico = "ico",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceEmail = "invoice_email",
  /** column name */
  IsCommunicationEmailSameAsInvoiceEmail = "is_communication_email_same_as_invoice_email",
  /** column name */
  PostalCode = "postal_code",
  /** column name */
  State = "state",
  /** column name */
  Street = "street",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organizations_Bool_Exp;
};

/** columns and relationships of "password_reset_requests" */
export type Password_Reset_Requests = {
  __typename?: "password_reset_requests";
  created_at: Scalars["timestamptz"]["output"];
  expires_at: Scalars["timestamptz"]["output"];
  finished: Scalars["Boolean"]["output"];
  id: Scalars["uuid"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  /** An object relationship */
  user_rel: Users;
  userid: Scalars["uuid"]["output"];
};

/** aggregated selection of "password_reset_requests" */
export type Password_Reset_Requests_Aggregate = {
  __typename?: "password_reset_requests_aggregate";
  aggregate?: Maybe<Password_Reset_Requests_Aggregate_Fields>;
  nodes: Array<Password_Reset_Requests>;
};

export type Password_Reset_Requests_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Password_Reset_Requests_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Password_Reset_Requests_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Password_Reset_Requests_Aggregate_Bool_Exp_Count>;
};

export type Password_Reset_Requests_Aggregate_Bool_Exp_Bool_And = {
  arguments: Password_Reset_Requests_Select_Column_Password_Reset_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Password_Reset_Requests_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Password_Reset_Requests_Select_Column_Password_Reset_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Password_Reset_Requests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "password_reset_requests" */
export type Password_Reset_Requests_Aggregate_Fields = {
  __typename?: "password_reset_requests_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Password_Reset_Requests_Max_Fields>;
  min?: Maybe<Password_Reset_Requests_Min_Fields>;
};

/** aggregate fields of "password_reset_requests" */
export type Password_Reset_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "password_reset_requests" */
export type Password_Reset_Requests_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Password_Reset_Requests_Max_Order_By>;
  min?: InputMaybe<Password_Reset_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "password_reset_requests" */
export type Password_Reset_Requests_Arr_Rel_Insert_Input = {
  data: Array<Password_Reset_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Password_Reset_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "password_reset_requests". All fields are combined with a logical 'AND'. */
export type Password_Reset_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Password_Reset_Requests_Bool_Exp>>;
  _not?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Password_Reset_Requests_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  finished?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_rel?: InputMaybe<Users_Bool_Exp>;
  userid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_reset_requests" */
export enum Password_Reset_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  PasswordResetRequestsPkey = "password_reset_requests_pkey",
}

/** input type for inserting data into table "password_reset_requests" */
export type Password_Reset_Requests_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user_rel?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** aggregate max on columns */
export type Password_Reset_Requests_Max_Fields = {
  __typename?: "password_reset_requests_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  expires_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  userid?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "password_reset_requests" */
export type Password_Reset_Requests_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Password_Reset_Requests_Min_Fields = {
  __typename?: "password_reset_requests_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  expires_at?: Maybe<Scalars["timestamptz"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  userid?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "password_reset_requests" */
export type Password_Reset_Requests_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "password_reset_requests" */
export type Password_Reset_Requests_Mutation_Response = {
  __typename?: "password_reset_requests_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Reset_Requests>;
};

/** on_conflict condition type for table "password_reset_requests" */
export type Password_Reset_Requests_On_Conflict = {
  constraint: Password_Reset_Requests_Constraint;
  update_columns?: Array<Password_Reset_Requests_Update_Column>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "password_reset_requests". */
export type Password_Reset_Requests_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  finished?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_rel?: InputMaybe<Users_Order_By>;
  userid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: password_reset_requests */
export type Password_Reset_Requests_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "password_reset_requests" */
export enum Password_Reset_Requests_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresAt = "expires_at",
  /** column name */
  Finished = "finished",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Userid = "userid",
}

/** select "password_reset_requests_aggregate_bool_exp_bool_and_arguments_columns" columns of table "password_reset_requests" */
export enum Password_Reset_Requests_Select_Column_Password_Reset_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Finished = "finished",
}

/** select "password_reset_requests_aggregate_bool_exp_bool_or_arguments_columns" columns of table "password_reset_requests" */
export enum Password_Reset_Requests_Select_Column_Password_Reset_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Finished = "finished",
}

/** input type for updating data in table "password_reset_requests" */
export type Password_Reset_Requests_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "password_reset_requests" */
export type Password_Reset_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Password_Reset_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Password_Reset_Requests_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  expires_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  finished?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  userid?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "password_reset_requests" */
export enum Password_Reset_Requests_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpiresAt = "expires_at",
  /** column name */
  Finished = "finished",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Userid = "userid",
}

export type Password_Reset_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Password_Reset_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Password_Reset_Requests_Bool_Exp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "case_law_ids_nsoud" */
  case_law_ids_nsoud: Array<Case_Law_Ids_Nsoud>;
  /** fetch aggregated fields from the table: "case_law_ids_nsoud" */
  case_law_ids_nsoud_aggregate: Case_Law_Ids_Nsoud_Aggregate;
  /** fetch data from the table: "case_law_ids_nsoud" using primary key columns */
  case_law_ids_nsoud_by_pk?: Maybe<Case_Law_Ids_Nsoud>;
  /** fetch data from the table: "case_law_ids_nssoud" */
  case_law_ids_nssoud: Array<Case_Law_Ids_Nssoud>;
  /** fetch aggregated fields from the table: "case_law_ids_nssoud" */
  case_law_ids_nssoud_aggregate: Case_Law_Ids_Nssoud_Aggregate;
  /** fetch data from the table: "case_law_ids_nssoud" using primary key columns */
  case_law_ids_nssoud_by_pk?: Maybe<Case_Law_Ids_Nssoud>;
  /** fetch data from the table: "case_law_ids_usoud" */
  case_law_ids_usoud: Array<Case_Law_Ids_Usoud>;
  /** fetch aggregated fields from the table: "case_law_ids_usoud" */
  case_law_ids_usoud_aggregate: Case_Law_Ids_Usoud_Aggregate;
  /** fetch data from the table: "case_law_ids_usoud" using primary key columns */
  case_law_ids_usoud_by_pk?: Maybe<Case_Law_Ids_Usoud>;
  check_engine_documents_call: CheckTokenResponse;
  check_engine_research_call: CheckTokenResponse;
  check_is_admin: Scalars["Boolean"]["output"];
  check_token: CheckTokenResponse;
  /** An array relationship */
  email_verification_requests: Array<Email_Verification_Requests>;
  /** An aggregate relationship */
  email_verification_requests_aggregate: Email_Verification_Requests_Aggregate;
  /** fetch data from the table: "email_verification_requests" using primary key columns */
  email_verification_requests_by_pk?: Maybe<Email_Verification_Requests>;
  /** fetch data from the table: "legislation_ids" */
  legislation_ids: Array<Legislation_Ids>;
  /** fetch aggregated fields from the table: "legislation_ids" */
  legislation_ids_aggregate: Legislation_Ids_Aggregate;
  /** fetch data from the table: "legislation_ids" using primary key columns */
  legislation_ids_by_pk?: Maybe<Legislation_Ids>;
  /** fetch data from the table: "my_user" */
  my_user: Array<My_User>;
  /** fetch aggregated fields from the table: "my_user" */
  my_user_aggregate: My_User_Aggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** An array relationship */
  password_reset_requests: Array<Password_Reset_Requests>;
  /** An aggregate relationship */
  password_reset_requests_aggregate: Password_Reset_Requests_Aggregate;
  /** fetch data from the table: "password_reset_requests" using primary key columns */
  password_reset_requests_by_pk?: Maybe<Password_Reset_Requests>;
  /** fetch data from the table: "raw_case_law_all" */
  raw_case_law_all: Array<Raw_Case_Law_All>;
  /** fetch aggregated fields from the table: "raw_case_law_all" */
  raw_case_law_all_aggregate: Raw_Case_Law_All_Aggregate;
  /** fetch data from the table: "raw_case_law_nsoud" */
  raw_case_law_nsoud: Array<Raw_Case_Law_Nsoud>;
  /** fetch aggregated fields from the table: "raw_case_law_nsoud" */
  raw_case_law_nsoud_aggregate: Raw_Case_Law_Nsoud_Aggregate;
  /** fetch data from the table: "raw_case_law_nsoud" using primary key columns */
  raw_case_law_nsoud_by_pk?: Maybe<Raw_Case_Law_Nsoud>;
  /** fetch data from the table: "raw_case_law_nssoud" */
  raw_case_law_nssoud: Array<Raw_Case_Law_Nssoud>;
  /** fetch aggregated fields from the table: "raw_case_law_nssoud" */
  raw_case_law_nssoud_aggregate: Raw_Case_Law_Nssoud_Aggregate;
  /** fetch data from the table: "raw_case_law_nssoud" using primary key columns */
  raw_case_law_nssoud_by_pk?: Maybe<Raw_Case_Law_Nssoud>;
  /** fetch data from the table: "raw_case_law_usoud" */
  raw_case_law_usoud: Array<Raw_Case_Law_Usoud>;
  /** fetch aggregated fields from the table: "raw_case_law_usoud" */
  raw_case_law_usoud_aggregate: Raw_Case_Law_Usoud_Aggregate;
  /** fetch data from the table: "raw_case_law_usoud" using primary key columns */
  raw_case_law_usoud_by_pk?: Maybe<Raw_Case_Law_Usoud>;
  /** fetch data from the table: "raw_legislation" */
  raw_legislation: Array<Raw_Legislation>;
  /** fetch aggregated fields from the table: "raw_legislation" */
  raw_legislation_aggregate: Raw_Legislation_Aggregate;
  /** fetch data from the table: "raw_legislation" using primary key columns */
  raw_legislation_by_pk?: Maybe<Raw_Legislation>;
  /** An array relationship */
  research: Array<Research>;
  /** An aggregate relationship */
  research_aggregate: Research_Aggregate;
  /** fetch data from the table: "research" using primary key columns */
  research_by_pk?: Maybe<Research>;
  /** fetch data from the table: "scraper_state" */
  scraper_state: Array<Scraper_State>;
  /** fetch aggregated fields from the table: "scraper_state" */
  scraper_state_aggregate: Scraper_State_Aggregate;
  /** fetch data from the table: "scraper_state" using primary key columns */
  scraper_state_by_pk?: Maybe<Scraper_State>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_favorite" */
  users_favorite: Array<Users_Favorite>;
  /** fetch aggregated fields from the table: "users_favorite" */
  users_favorite_aggregate: Users_Favorite_Aggregate;
  /** fetch data from the table: "users_favorite" using primary key columns */
  users_favorite_by_pk?: Maybe<Users_Favorite>;
  /** fetch data from the table: "users_history" */
  users_history: Array<Users_History>;
  /** fetch aggregated fields from the table: "users_history" */
  users_history_aggregate: Users_History_Aggregate;
  /** fetch data from the table: "users_history" using primary key columns */
  users_history_by_pk?: Maybe<Users_History>;
};

export type Query_RootCase_Law_Ids_NsoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nsoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Nsoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nsoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Query_RootCase_Law_Ids_NssoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nssoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Nssoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nssoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Query_RootCase_Law_Ids_UsoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Usoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Usoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Usoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

export type Query_RootCase_Law_Ids_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Query_RootCheck_Engine_Documents_CallArgs = {
  token: Scalars["String"]["input"];
};

export type Query_RootCheck_Engine_Research_CallArgs = {
  token: Scalars["String"]["input"];
};

export type Query_RootCheck_Is_AdminArgs = {
  token: Scalars["String"]["input"];
};

export type Query_RootCheck_TokenArgs = {
  token: Scalars["String"]["input"];
};

export type Query_RootEmail_Verification_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

export type Query_RootEmail_Verification_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

export type Query_RootEmail_Verification_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootLegislation_IdsArgs = {
  distinct_on?: InputMaybe<Array<Legislation_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Legislation_Ids_Order_By>>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

export type Query_RootLegislation_Ids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legislation_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Legislation_Ids_Order_By>>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

export type Query_RootLegislation_Ids_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Query_RootMy_UserArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};

export type Query_RootMy_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};

export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootPassword_Reset_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

export type Query_RootPassword_Reset_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

export type Query_RootPassword_Reset_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootRaw_Case_Law_AllArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_All_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_All_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_All_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_All_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_All_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_NsoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nsoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Nsoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nsoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Query_RootRaw_Case_Law_NssoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nssoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Nssoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nssoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Query_RootRaw_Case_Law_UsoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Usoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Usoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Usoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

export type Query_RootRaw_Case_Law_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Query_RootRaw_LegislationArgs = {
  distinct_on?: InputMaybe<Array<Raw_Legislation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Legislation_Order_By>>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

export type Query_RootRaw_Legislation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Legislation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Legislation_Order_By>>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

export type Query_RootRaw_Legislation_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Query_RootResearchArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

export type Query_RootResearch_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

export type Query_RootResearch_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootScraper_StateArgs = {
  distinct_on?: InputMaybe<Array<Scraper_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Scraper_State_Order_By>>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

export type Query_RootScraper_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scraper_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Scraper_State_Order_By>>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

export type Query_RootScraper_State_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootUsers_FavoriteArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

export type Query_RootUsers_Favorite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

export type Query_RootUsers_Favorite_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Query_RootUsers_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

export type Query_RootUsers_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

export type Query_RootUsers_History_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

/** columns and relationships of "raw_case_law_all" */
export type Raw_Case_Law_All = {
  __typename?: "raw_case_law_all";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  court_type?: Maybe<Scalars["String"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  regulations?: Maybe<Scalars["jsonb"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** columns and relationships of "raw_case_law_all" */
export type Raw_Case_Law_AllRegulationsArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "raw_case_law_all" */
export type Raw_Case_Law_All_Aggregate = {
  __typename?: "raw_case_law_all_aggregate";
  aggregate?: Maybe<Raw_Case_Law_All_Aggregate_Fields>;
  nodes: Array<Raw_Case_Law_All>;
};

/** aggregate fields of "raw_case_law_all" */
export type Raw_Case_Law_All_Aggregate_Fields = {
  __typename?: "raw_case_law_all_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Raw_Case_Law_All_Max_Fields>;
  min?: Maybe<Raw_Case_Law_All_Min_Fields>;
};

/** aggregate fields of "raw_case_law_all" */
export type Raw_Case_Law_All_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Case_Law_All_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "raw_case_law_all". All fields are combined with a logical 'AND'. */
export type Raw_Case_Law_All_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Case_Law_All_Bool_Exp>>;
  _not?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Case_Law_All_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  court?: InputMaybe<String_Comparison_Exp>;
  court_type?: InputMaybe<String_Comparison_Exp>;
  decision_date?: InputMaybe<Date_Comparison_Exp>;
  decision_type?: InputMaybe<String_Comparison_Exp>;
  ecli?: InputMaybe<String_Comparison_Exp>;
  file_mark?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  regulations?: InputMaybe<Jsonb_Comparison_Exp>;
  senate_mark?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Raw_Case_Law_All_Max_Fields = {
  __typename?: "raw_case_law_all_max_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  court_type?: Maybe<Scalars["String"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Raw_Case_Law_All_Min_Fields = {
  __typename?: "raw_case_law_all_min_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  court_type?: Maybe<Scalars["String"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** Ordering options when selecting data from "raw_case_law_all". */
export type Raw_Case_Law_All_Order_By = {
  content?: InputMaybe<Order_By>;
  court?: InputMaybe<Order_By>;
  court_type?: InputMaybe<Order_By>;
  decision_date?: InputMaybe<Order_By>;
  decision_type?: InputMaybe<Order_By>;
  ecli?: InputMaybe<Order_By>;
  file_mark?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  regulations?: InputMaybe<Order_By>;
  senate_mark?: InputMaybe<Order_By>;
};

/** select columns of table "raw_case_law_all" */
export enum Raw_Case_Law_All_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  CourtType = "court_type",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  Regulations = "regulations",
  /** column name */
  SenateMark = "senate_mark",
}

/** Streaming cursor of the table "raw_case_law_all" */
export type Raw_Case_Law_All_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Case_Law_All_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Case_Law_All_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  court_type?: InputMaybe<Scalars["String"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  regulations?: InputMaybe<Scalars["jsonb"]["input"]>;
  senate_mark?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud = {
  __typename?: "raw_case_law_nsoud";
  content: Scalars["String"]["output"];
  court: Scalars["String"]["output"];
  decision_categories: Array<Scalars["String"]["output"]>;
  decision_date: Scalars["date"]["output"];
  decision_type: Scalars["String"]["output"];
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  keywords?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  regulations: Array<Scalars["String"]["output"]>;
  report?: Maybe<Scalars["String"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Aggregate = {
  __typename?: "raw_case_law_nsoud_aggregate";
  aggregate?: Maybe<Raw_Case_Law_Nsoud_Aggregate_Fields>;
  nodes: Array<Raw_Case_Law_Nsoud>;
};

/** aggregate fields of "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Aggregate_Fields = {
  __typename?: "raw_case_law_nsoud_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Raw_Case_Law_Nsoud_Max_Fields>;
  min?: Maybe<Raw_Case_Law_Nsoud_Min_Fields>;
};

/** aggregate fields of "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Case_Law_Nsoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "raw_case_law_nsoud". All fields are combined with a logical 'AND'. */
export type Raw_Case_Law_Nsoud_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Case_Law_Nsoud_Bool_Exp>>;
  _not?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Case_Law_Nsoud_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  court?: InputMaybe<String_Comparison_Exp>;
  decision_categories?: InputMaybe<String_Array_Comparison_Exp>;
  decision_date?: InputMaybe<Date_Comparison_Exp>;
  decision_type?: InputMaybe<String_Comparison_Exp>;
  ecli?: InputMaybe<String_Comparison_Exp>;
  file_mark?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  keywords?: InputMaybe<String_Array_Comparison_Exp>;
  publish_date?: InputMaybe<Date_Comparison_Exp>;
  regulations?: InputMaybe<String_Array_Comparison_Exp>;
  report?: InputMaybe<String_Comparison_Exp>;
  senate_mark?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_case_law_nsoud" */
export enum Raw_Case_Law_Nsoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawCaseLawNsoudPkey = "raw_case_law_nsoud_pkey",
}

/** input type for inserting data into table "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Insert_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  keywords?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  report?: InputMaybe<Scalars["String"]["input"]>;
  senate_mark?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Raw_Case_Law_Nsoud_Max_Fields = {
  __typename?: "raw_case_law_nsoud_max_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_categories?: Maybe<Array<Scalars["String"]["output"]>>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  keywords?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  regulations?: Maybe<Array<Scalars["String"]["output"]>>;
  report?: Maybe<Scalars["String"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Raw_Case_Law_Nsoud_Min_Fields = {
  __typename?: "raw_case_law_nsoud_min_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_categories?: Maybe<Array<Scalars["String"]["output"]>>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  keywords?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  regulations?: Maybe<Array<Scalars["String"]["output"]>>;
  report?: Maybe<Scalars["String"]["output"]>;
  senate_mark?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Mutation_Response = {
  __typename?: "raw_case_law_nsoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Case_Law_Nsoud>;
};

/** on_conflict condition type for table "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_On_Conflict = {
  constraint: Raw_Case_Law_Nsoud_Constraint;
  update_columns?: Array<Raw_Case_Law_Nsoud_Update_Column>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_case_law_nsoud". */
export type Raw_Case_Law_Nsoud_Order_By = {
  content?: InputMaybe<Order_By>;
  court?: InputMaybe<Order_By>;
  decision_categories?: InputMaybe<Order_By>;
  decision_date?: InputMaybe<Order_By>;
  decision_type?: InputMaybe<Order_By>;
  ecli?: InputMaybe<Order_By>;
  file_mark?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  keywords?: InputMaybe<Order_By>;
  publish_date?: InputMaybe<Order_By>;
  regulations?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  senate_mark?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_case_law_nsoud */
export type Raw_Case_Law_Nsoud_Pk_Columns_Input = {
  id: Scalars["String"]["input"];
};

/** select columns of table "raw_case_law_nsoud" */
export enum Raw_Case_Law_Nsoud_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionCategories = "decision_categories",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  Keywords = "keywords",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  Regulations = "regulations",
  /** column name */
  Report = "report",
  /** column name */
  SenateMark = "senate_mark",
}

/** input type for updating data in table "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Set_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  keywords?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  report?: InputMaybe<Scalars["String"]["input"]>;
  senate_mark?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "raw_case_law_nsoud" */
export type Raw_Case_Law_Nsoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Case_Law_Nsoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Case_Law_Nsoud_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  keywords?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  report?: InputMaybe<Scalars["String"]["input"]>;
  senate_mark?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "raw_case_law_nsoud" */
export enum Raw_Case_Law_Nsoud_Update_Column {
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionCategories = "decision_categories",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  Keywords = "keywords",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  Regulations = "regulations",
  /** column name */
  Report = "report",
  /** column name */
  SenateMark = "senate_mark",
}

export type Raw_Case_Law_Nsoud_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Case_Law_Nsoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Case_Law_Nsoud_Bool_Exp;
};

/** columns and relationships of "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud = {
  __typename?: "raw_case_law_nssoud";
  content: Scalars["String"]["output"];
  court: Scalars["String"]["output"];
  decision_date: Scalars["date"]["output"];
  decision_type: Scalars["String"]["output"];
  ecli: Scalars["String"]["output"];
  file_mark: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  proceedings_type?: Maybe<Scalars["String"]["output"]>;
  publish_date: Scalars["date"]["output"];
  statement_type: Scalars["String"]["output"];
};

/** aggregated selection of "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Aggregate = {
  __typename?: "raw_case_law_nssoud_aggregate";
  aggregate?: Maybe<Raw_Case_Law_Nssoud_Aggregate_Fields>;
  nodes: Array<Raw_Case_Law_Nssoud>;
};

/** aggregate fields of "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Aggregate_Fields = {
  __typename?: "raw_case_law_nssoud_aggregate_fields";
  avg?: Maybe<Raw_Case_Law_Nssoud_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Raw_Case_Law_Nssoud_Max_Fields>;
  min?: Maybe<Raw_Case_Law_Nssoud_Min_Fields>;
  stddev?: Maybe<Raw_Case_Law_Nssoud_Stddev_Fields>;
  stddev_pop?: Maybe<Raw_Case_Law_Nssoud_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raw_Case_Law_Nssoud_Stddev_Samp_Fields>;
  sum?: Maybe<Raw_Case_Law_Nssoud_Sum_Fields>;
  var_pop?: Maybe<Raw_Case_Law_Nssoud_Var_Pop_Fields>;
  var_samp?: Maybe<Raw_Case_Law_Nssoud_Var_Samp_Fields>;
  variance?: Maybe<Raw_Case_Law_Nssoud_Variance_Fields>;
};

/** aggregate fields of "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Case_Law_Nssoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Raw_Case_Law_Nssoud_Avg_Fields = {
  __typename?: "raw_case_law_nssoud_avg_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "raw_case_law_nssoud". All fields are combined with a logical 'AND'. */
export type Raw_Case_Law_Nssoud_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Case_Law_Nssoud_Bool_Exp>>;
  _not?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Case_Law_Nssoud_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  court?: InputMaybe<String_Comparison_Exp>;
  decision_date?: InputMaybe<Date_Comparison_Exp>;
  decision_type?: InputMaybe<String_Comparison_Exp>;
  ecli?: InputMaybe<String_Comparison_Exp>;
  file_mark?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  proceedings_type?: InputMaybe<String_Comparison_Exp>;
  publish_date?: InputMaybe<Date_Comparison_Exp>;
  statement_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_case_law_nssoud" */
export enum Raw_Case_Law_Nssoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawCaseLawNssoudIdKey = "raw_case_law_nssoud_id_key",
  /** unique or primary key constraint on columns "id" */
  RawCaseLawNssoudPkey = "raw_case_law_nssoud_pkey",
}

/** input type for incrementing numeric columns in table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Insert_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  proceedings_type?: InputMaybe<Scalars["String"]["input"]>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  statement_type?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Raw_Case_Law_Nssoud_Max_Fields = {
  __typename?: "raw_case_law_nssoud_max_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  proceedings_type?: Maybe<Scalars["String"]["output"]>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  statement_type?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Raw_Case_Law_Nssoud_Min_Fields = {
  __typename?: "raw_case_law_nssoud_min_fields";
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  proceedings_type?: Maybe<Scalars["String"]["output"]>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  statement_type?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Mutation_Response = {
  __typename?: "raw_case_law_nssoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Case_Law_Nssoud>;
};

/** on_conflict condition type for table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_On_Conflict = {
  constraint: Raw_Case_Law_Nssoud_Constraint;
  update_columns?: Array<Raw_Case_Law_Nssoud_Update_Column>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_case_law_nssoud". */
export type Raw_Case_Law_Nssoud_Order_By = {
  content?: InputMaybe<Order_By>;
  court?: InputMaybe<Order_By>;
  decision_date?: InputMaybe<Order_By>;
  decision_type?: InputMaybe<Order_By>;
  ecli?: InputMaybe<Order_By>;
  file_mark?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  proceedings_type?: InputMaybe<Order_By>;
  publish_date?: InputMaybe<Order_By>;
  statement_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_case_law_nssoud */
export type Raw_Case_Law_Nssoud_Pk_Columns_Input = {
  id: Scalars["Int"]["input"];
};

/** select columns of table "raw_case_law_nssoud" */
export enum Raw_Case_Law_Nssoud_Select_Column {
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  ProceedingsType = "proceedings_type",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  StatementType = "statement_type",
}

/** input type for updating data in table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Set_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  proceedings_type?: InputMaybe<Scalars["String"]["input"]>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  statement_type?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate stddev on columns */
export type Raw_Case_Law_Nssoud_Stddev_Fields = {
  __typename?: "raw_case_law_nssoud_stddev_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Raw_Case_Law_Nssoud_Stddev_Pop_Fields = {
  __typename?: "raw_case_law_nssoud_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Raw_Case_Law_Nssoud_Stddev_Samp_Fields = {
  __typename?: "raw_case_law_nssoud_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "raw_case_law_nssoud" */
export type Raw_Case_Law_Nssoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Case_Law_Nssoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Case_Law_Nssoud_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  proceedings_type?: InputMaybe<Scalars["String"]["input"]>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  statement_type?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate sum on columns */
export type Raw_Case_Law_Nssoud_Sum_Fields = {
  __typename?: "raw_case_law_nssoud_sum_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "raw_case_law_nssoud" */
export enum Raw_Case_Law_Nssoud_Update_Column {
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  ProceedingsType = "proceedings_type",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  StatementType = "statement_type",
}

export type Raw_Case_Law_Nssoud_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Raw_Case_Law_Nssoud_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Case_Law_Nssoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Case_Law_Nssoud_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Raw_Case_Law_Nssoud_Var_Pop_Fields = {
  __typename?: "raw_case_law_nssoud_var_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Raw_Case_Law_Nssoud_Var_Samp_Fields = {
  __typename?: "raw_case_law_nssoud_var_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Raw_Case_Law_Nssoud_Variance_Fields = {
  __typename?: "raw_case_law_nssoud_variance_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud = {
  __typename?: "raw_case_law_usoud";
  challenged_act?: Maybe<Array<Scalars["String"]["output"]>>;
  content: Scalars["String"]["output"];
  court: Scalars["String"]["output"];
  decision_category: Scalars["bpchar"]["output"];
  decision_date: Scalars["date"]["output"];
  decision_type: Scalars["String"]["output"];
  ecli: Scalars["String"]["output"];
  entry_date: Scalars["date"]["output"];
  file_mark: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  is_procedural: Scalars["Boolean"]["output"];
  material_index: Array<Scalars["String"]["output"]>;
  proceedings_subject?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date: Scalars["date"]["output"];
  regulations: Array<Scalars["String"]["output"]>;
  statement_type: Array<Scalars["String"]["output"]>;
};

/** aggregated selection of "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Aggregate = {
  __typename?: "raw_case_law_usoud_aggregate";
  aggregate?: Maybe<Raw_Case_Law_Usoud_Aggregate_Fields>;
  nodes: Array<Raw_Case_Law_Usoud>;
};

/** aggregate fields of "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Aggregate_Fields = {
  __typename?: "raw_case_law_usoud_aggregate_fields";
  avg?: Maybe<Raw_Case_Law_Usoud_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Raw_Case_Law_Usoud_Max_Fields>;
  min?: Maybe<Raw_Case_Law_Usoud_Min_Fields>;
  stddev?: Maybe<Raw_Case_Law_Usoud_Stddev_Fields>;
  stddev_pop?: Maybe<Raw_Case_Law_Usoud_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Raw_Case_Law_Usoud_Stddev_Samp_Fields>;
  sum?: Maybe<Raw_Case_Law_Usoud_Sum_Fields>;
  var_pop?: Maybe<Raw_Case_Law_Usoud_Var_Pop_Fields>;
  var_samp?: Maybe<Raw_Case_Law_Usoud_Var_Samp_Fields>;
  variance?: Maybe<Raw_Case_Law_Usoud_Variance_Fields>;
};

/** aggregate fields of "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Case_Law_Usoud_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate avg on columns */
export type Raw_Case_Law_Usoud_Avg_Fields = {
  __typename?: "raw_case_law_usoud_avg_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "raw_case_law_usoud". All fields are combined with a logical 'AND'. */
export type Raw_Case_Law_Usoud_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Case_Law_Usoud_Bool_Exp>>;
  _not?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Case_Law_Usoud_Bool_Exp>>;
  challenged_act?: InputMaybe<String_Array_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  court?: InputMaybe<String_Comparison_Exp>;
  decision_category?: InputMaybe<Bpchar_Comparison_Exp>;
  decision_date?: InputMaybe<Date_Comparison_Exp>;
  decision_type?: InputMaybe<String_Comparison_Exp>;
  ecli?: InputMaybe<String_Comparison_Exp>;
  entry_date?: InputMaybe<Date_Comparison_Exp>;
  file_mark?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_procedural?: InputMaybe<Boolean_Comparison_Exp>;
  material_index?: InputMaybe<String_Array_Comparison_Exp>;
  proceedings_subject?: InputMaybe<String_Array_Comparison_Exp>;
  publish_date?: InputMaybe<Date_Comparison_Exp>;
  regulations?: InputMaybe<String_Array_Comparison_Exp>;
  statement_type?: InputMaybe<String_Array_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_case_law_usoud" */
export enum Raw_Case_Law_Usoud_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawCaseLawUsoudIdKey = "raw_case_law_usoud_id_key",
  /** unique or primary key constraint on columns "id" */
  RawCaseLawUsoudPkey = "raw_case_law_usoud_pkey",
}

/** input type for incrementing numeric columns in table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Inc_Input = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Insert_Input = {
  challenged_act?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_category?: InputMaybe<Scalars["bpchar"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  entry_date?: InputMaybe<Scalars["date"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  is_procedural?: InputMaybe<Scalars["Boolean"]["input"]>;
  material_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  proceedings_subject?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statement_type?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** aggregate max on columns */
export type Raw_Case_Law_Usoud_Max_Fields = {
  __typename?: "raw_case_law_usoud_max_fields";
  challenged_act?: Maybe<Array<Scalars["String"]["output"]>>;
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_category?: Maybe<Scalars["bpchar"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  entry_date?: Maybe<Scalars["date"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  material_index?: Maybe<Array<Scalars["String"]["output"]>>;
  proceedings_subject?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  regulations?: Maybe<Array<Scalars["String"]["output"]>>;
  statement_type?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** aggregate min on columns */
export type Raw_Case_Law_Usoud_Min_Fields = {
  __typename?: "raw_case_law_usoud_min_fields";
  challenged_act?: Maybe<Array<Scalars["String"]["output"]>>;
  content?: Maybe<Scalars["String"]["output"]>;
  court?: Maybe<Scalars["String"]["output"]>;
  decision_category?: Maybe<Scalars["bpchar"]["output"]>;
  decision_date?: Maybe<Scalars["date"]["output"]>;
  decision_type?: Maybe<Scalars["String"]["output"]>;
  ecli?: Maybe<Scalars["String"]["output"]>;
  entry_date?: Maybe<Scalars["date"]["output"]>;
  file_mark?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  material_index?: Maybe<Array<Scalars["String"]["output"]>>;
  proceedings_subject?: Maybe<Array<Scalars["String"]["output"]>>;
  publish_date?: Maybe<Scalars["date"]["output"]>;
  regulations?: Maybe<Array<Scalars["String"]["output"]>>;
  statement_type?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** response of any mutation on the table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Mutation_Response = {
  __typename?: "raw_case_law_usoud_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Case_Law_Usoud>;
};

/** on_conflict condition type for table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_On_Conflict = {
  constraint: Raw_Case_Law_Usoud_Constraint;
  update_columns?: Array<Raw_Case_Law_Usoud_Update_Column>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_case_law_usoud". */
export type Raw_Case_Law_Usoud_Order_By = {
  challenged_act?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  court?: InputMaybe<Order_By>;
  decision_category?: InputMaybe<Order_By>;
  decision_date?: InputMaybe<Order_By>;
  decision_type?: InputMaybe<Order_By>;
  ecli?: InputMaybe<Order_By>;
  entry_date?: InputMaybe<Order_By>;
  file_mark?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_procedural?: InputMaybe<Order_By>;
  material_index?: InputMaybe<Order_By>;
  proceedings_subject?: InputMaybe<Order_By>;
  publish_date?: InputMaybe<Order_By>;
  regulations?: InputMaybe<Order_By>;
  statement_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_case_law_usoud */
export type Raw_Case_Law_Usoud_Pk_Columns_Input = {
  id: Scalars["Int"]["input"];
};

/** select columns of table "raw_case_law_usoud" */
export enum Raw_Case_Law_Usoud_Select_Column {
  /** column name */
  ChallengedAct = "challenged_act",
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionCategory = "decision_category",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  EntryDate = "entry_date",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  IsProcedural = "is_procedural",
  /** column name */
  MaterialIndex = "material_index",
  /** column name */
  ProceedingsSubject = "proceedings_subject",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  Regulations = "regulations",
  /** column name */
  StatementType = "statement_type",
}

/** input type for updating data in table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Set_Input = {
  challenged_act?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_category?: InputMaybe<Scalars["bpchar"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  entry_date?: InputMaybe<Scalars["date"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  is_procedural?: InputMaybe<Scalars["Boolean"]["input"]>;
  material_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  proceedings_subject?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statement_type?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** aggregate stddev on columns */
export type Raw_Case_Law_Usoud_Stddev_Fields = {
  __typename?: "raw_case_law_usoud_stddev_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Raw_Case_Law_Usoud_Stddev_Pop_Fields = {
  __typename?: "raw_case_law_usoud_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Raw_Case_Law_Usoud_Stddev_Samp_Fields = {
  __typename?: "raw_case_law_usoud_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "raw_case_law_usoud" */
export type Raw_Case_Law_Usoud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Case_Law_Usoud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Case_Law_Usoud_Stream_Cursor_Value_Input = {
  challenged_act?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  court?: InputMaybe<Scalars["String"]["input"]>;
  decision_category?: InputMaybe<Scalars["bpchar"]["input"]>;
  decision_date?: InputMaybe<Scalars["date"]["input"]>;
  decision_type?: InputMaybe<Scalars["String"]["input"]>;
  ecli?: InputMaybe<Scalars["String"]["input"]>;
  entry_date?: InputMaybe<Scalars["date"]["input"]>;
  file_mark?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  is_procedural?: InputMaybe<Scalars["Boolean"]["input"]>;
  material_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  proceedings_subject?: InputMaybe<Array<Scalars["String"]["input"]>>;
  publish_date?: InputMaybe<Scalars["date"]["input"]>;
  regulations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  statement_type?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** aggregate sum on columns */
export type Raw_Case_Law_Usoud_Sum_Fields = {
  __typename?: "raw_case_law_usoud_sum_fields";
  id?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "raw_case_law_usoud" */
export enum Raw_Case_Law_Usoud_Update_Column {
  /** column name */
  ChallengedAct = "challenged_act",
  /** column name */
  Content = "content",
  /** column name */
  Court = "court",
  /** column name */
  DecisionCategory = "decision_category",
  /** column name */
  DecisionDate = "decision_date",
  /** column name */
  DecisionType = "decision_type",
  /** column name */
  Ecli = "ecli",
  /** column name */
  EntryDate = "entry_date",
  /** column name */
  FileMark = "file_mark",
  /** column name */
  Id = "id",
  /** column name */
  IsProcedural = "is_procedural",
  /** column name */
  MaterialIndex = "material_index",
  /** column name */
  ProceedingsSubject = "proceedings_subject",
  /** column name */
  PublishDate = "publish_date",
  /** column name */
  Regulations = "regulations",
  /** column name */
  StatementType = "statement_type",
}

export type Raw_Case_Law_Usoud_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Raw_Case_Law_Usoud_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Case_Law_Usoud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Case_Law_Usoud_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Raw_Case_Law_Usoud_Var_Pop_Fields = {
  __typename?: "raw_case_law_usoud_var_pop_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Raw_Case_Law_Usoud_Var_Samp_Fields = {
  __typename?: "raw_case_law_usoud_var_samp_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Raw_Case_Law_Usoud_Variance_Fields = {
  __typename?: "raw_case_law_usoud_variance_fields";
  id?: Maybe<Scalars["Float"]["output"]>;
};

/** columns and relationships of "raw_legislation" */
export type Raw_Legislation = {
  __typename?: "raw_legislation";
  cancelled_at?: Maybe<Scalars["date"]["output"]>;
  content: Scalars["String"]["output"];
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  effective_from?: Maybe<Scalars["date"]["output"]>;
  id: Scalars["String"]["output"];
  is_outdated: Scalars["Boolean"]["output"];
  systematic_index?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  valid_from?: Maybe<Scalars["date"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

/** aggregated selection of "raw_legislation" */
export type Raw_Legislation_Aggregate = {
  __typename?: "raw_legislation_aggregate";
  aggregate?: Maybe<Raw_Legislation_Aggregate_Fields>;
  nodes: Array<Raw_Legislation>;
};

/** aggregate fields of "raw_legislation" */
export type Raw_Legislation_Aggregate_Fields = {
  __typename?: "raw_legislation_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Raw_Legislation_Max_Fields>;
  min?: Maybe<Raw_Legislation_Min_Fields>;
};

/** aggregate fields of "raw_legislation" */
export type Raw_Legislation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Raw_Legislation_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "raw_legislation". All fields are combined with a logical 'AND'. */
export type Raw_Legislation_Bool_Exp = {
  _and?: InputMaybe<Array<Raw_Legislation_Bool_Exp>>;
  _not?: InputMaybe<Raw_Legislation_Bool_Exp>;
  _or?: InputMaybe<Array<Raw_Legislation_Bool_Exp>>;
  cancelled_at?: InputMaybe<Date_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  effective_from?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_outdated?: InputMaybe<Boolean_Comparison_Exp>;
  systematic_index?: InputMaybe<String_Array_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  valid_from?: InputMaybe<Date_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "raw_legislation" */
export enum Raw_Legislation_Constraint {
  /** unique or primary key constraint on columns "id" */
  RawLegislationIdKey = "raw_legislation_id_key",
  /** unique or primary key constraint on columns "id" */
  RawLegislationPkey = "raw_legislation_pkey",
}

/** input type for inserting data into table "raw_legislation" */
export type Raw_Legislation_Insert_Input = {
  cancelled_at?: InputMaybe<Scalars["date"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  effective_from?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  is_outdated?: InputMaybe<Scalars["Boolean"]["input"]>;
  systematic_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  valid_from?: InputMaybe<Scalars["date"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Raw_Legislation_Max_Fields = {
  __typename?: "raw_legislation_max_fields";
  cancelled_at?: Maybe<Scalars["date"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  effective_from?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  systematic_index?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  valid_from?: Maybe<Scalars["date"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Raw_Legislation_Min_Fields = {
  __typename?: "raw_legislation_min_fields";
  cancelled_at?: Maybe<Scalars["date"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  effective_from?: Maybe<Scalars["date"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  systematic_index?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  valid_from?: Maybe<Scalars["date"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "raw_legislation" */
export type Raw_Legislation_Mutation_Response = {
  __typename?: "raw_legislation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Raw_Legislation>;
};

/** on_conflict condition type for table "raw_legislation" */
export type Raw_Legislation_On_Conflict = {
  constraint: Raw_Legislation_Constraint;
  update_columns?: Array<Raw_Legislation_Update_Column>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

/** Ordering options when selecting data from "raw_legislation". */
export type Raw_Legislation_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  effective_from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_outdated?: InputMaybe<Order_By>;
  systematic_index?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  valid_from?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: raw_legislation */
export type Raw_Legislation_Pk_Columns_Input = {
  id: Scalars["String"]["input"];
};

/** select columns of table "raw_legislation" */
export enum Raw_Legislation_Select_Column {
  /** column name */
  CancelledAt = "cancelled_at",
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EffectiveFrom = "effective_from",
  /** column name */
  Id = "id",
  /** column name */
  IsOutdated = "is_outdated",
  /** column name */
  SystematicIndex = "systematic_index",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  Version = "version",
}

/** input type for updating data in table "raw_legislation" */
export type Raw_Legislation_Set_Input = {
  cancelled_at?: InputMaybe<Scalars["date"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  effective_from?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  is_outdated?: InputMaybe<Scalars["Boolean"]["input"]>;
  systematic_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  valid_from?: InputMaybe<Scalars["date"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "raw_legislation" */
export type Raw_Legislation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Raw_Legislation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Raw_Legislation_Stream_Cursor_Value_Input = {
  cancelled_at?: InputMaybe<Scalars["date"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  effective_from?: InputMaybe<Scalars["date"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  is_outdated?: InputMaybe<Scalars["Boolean"]["input"]>;
  systematic_index?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  valid_from?: InputMaybe<Scalars["date"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "raw_legislation" */
export enum Raw_Legislation_Update_Column {
  /** column name */
  CancelledAt = "cancelled_at",
  /** column name */
  Content = "content",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EffectiveFrom = "effective_from",
  /** column name */
  Id = "id",
  /** column name */
  IsOutdated = "is_outdated",
  /** column name */
  SystematicIndex = "systematic_index",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  Version = "version",
}

export type Raw_Legislation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Raw_Legislation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Raw_Legislation_Bool_Exp;
};

/** columns and relationships of "research" */
export type Research = {
  __typename?: "research";
  chat?: Maybe<Scalars["jsonb"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  html?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["uuid"]["output"];
  is_shared: Scalars["Boolean"]["output"];
  question?: Maybe<Scalars["String"]["output"]>;
  related_questions?: Maybe<Array<Scalars["String"]["output"]>>;
  resources?: Maybe<Scalars["jsonb"]["output"]>;
  threadId?: Maybe<Scalars["String"]["output"]>;
  traceId?: Maybe<Scalars["String"]["output"]>;
  updated_at: Scalars["timestamptz"]["output"];
  user: Scalars["uuid"]["output"];
  /** An object relationship */
  user_rel: Users;
  with_follow_up: Scalars["Boolean"]["output"];
};

/** columns and relationships of "research" */
export type ResearchChatArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "research" */
export type ResearchResourcesArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregated selection of "research" */
export type Research_Aggregate = {
  __typename?: "research_aggregate";
  aggregate?: Maybe<Research_Aggregate_Fields>;
  nodes: Array<Research>;
};

export type Research_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Research_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Research_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Research_Aggregate_Bool_Exp_Count>;
};

export type Research_Aggregate_Bool_Exp_Bool_And = {
  arguments: Research_Select_Column_Research_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Research_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Research_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Research_Select_Column_Research_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Research_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Research_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Research_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Research_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "research" */
export type Research_Aggregate_Fields = {
  __typename?: "research_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Research_Max_Fields>;
  min?: Maybe<Research_Min_Fields>;
};

/** aggregate fields of "research" */
export type Research_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Research_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "research" */
export type Research_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Research_Max_Order_By>;
  min?: InputMaybe<Research_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Research_Append_Input = {
  chat?: InputMaybe<Scalars["jsonb"]["input"]>;
  resources?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** input type for inserting array relation for remote table "research" */
export type Research_Arr_Rel_Insert_Input = {
  data: Array<Research_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Research_On_Conflict>;
};

/** Boolean expression to filter rows from the table "research". All fields are combined with a logical 'AND'. */
export type Research_Bool_Exp = {
  _and?: InputMaybe<Array<Research_Bool_Exp>>;
  _not?: InputMaybe<Research_Bool_Exp>;
  _or?: InputMaybe<Array<Research_Bool_Exp>>;
  chat?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  html?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_shared?: InputMaybe<Boolean_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  related_questions?: InputMaybe<String_Array_Comparison_Exp>;
  resources?: InputMaybe<Jsonb_Comparison_Exp>;
  threadId?: InputMaybe<String_Comparison_Exp>;
  traceId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Uuid_Comparison_Exp>;
  user_rel?: InputMaybe<Users_Bool_Exp>;
  with_follow_up?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "research" */
export enum Research_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResearchPkey = "research_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Research_Delete_At_Path_Input = {
  chat?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resources?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Research_Delete_Elem_Input = {
  chat?: InputMaybe<Scalars["Int"]["input"]>;
  resources?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Research_Delete_Key_Input = {
  chat?: InputMaybe<Scalars["String"]["input"]>;
  resources?: InputMaybe<Scalars["String"]["input"]>;
};

/** input type for inserting data into table "research" */
export type Research_Insert_Input = {
  chat?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_shared?: InputMaybe<Scalars["Boolean"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  related_questions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resources?: InputMaybe<Scalars["jsonb"]["input"]>;
  threadId?: InputMaybe<Scalars["String"]["input"]>;
  traceId?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
  user_rel?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  with_follow_up?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type Research_Max_Fields = {
  __typename?: "research_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  html?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  related_questions?: Maybe<Array<Scalars["String"]["output"]>>;
  threadId?: Maybe<Scalars["String"]["output"]>;
  traceId?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "research" */
export type Research_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  related_questions?: InputMaybe<Order_By>;
  threadId?: InputMaybe<Order_By>;
  traceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Research_Min_Fields = {
  __typename?: "research_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  html?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  related_questions?: Maybe<Array<Scalars["String"]["output"]>>;
  threadId?: Maybe<Scalars["String"]["output"]>;
  traceId?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "research" */
export type Research_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  related_questions?: InputMaybe<Order_By>;
  threadId?: InputMaybe<Order_By>;
  traceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "research" */
export type Research_Mutation_Response = {
  __typename?: "research_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Research>;
};

/** on_conflict condition type for table "research" */
export type Research_On_Conflict = {
  constraint: Research_Constraint;
  update_columns?: Array<Research_Update_Column>;
  where?: InputMaybe<Research_Bool_Exp>;
};

/** Ordering options when selecting data from "research". */
export type Research_Order_By = {
  chat?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_shared?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  related_questions?: InputMaybe<Order_By>;
  resources?: InputMaybe<Order_By>;
  threadId?: InputMaybe<Order_By>;
  traceId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_rel?: InputMaybe<Users_Order_By>;
  with_follow_up?: InputMaybe<Order_By>;
};

/** primary key columns input for table: research */
export type Research_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Research_Prepend_Input = {
  chat?: InputMaybe<Scalars["jsonb"]["input"]>;
  resources?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "research" */
export enum Research_Select_Column {
  /** column name */
  Chat = "chat",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Html = "html",
  /** column name */
  Id = "id",
  /** column name */
  IsShared = "is_shared",
  /** column name */
  Question = "question",
  /** column name */
  RelatedQuestions = "related_questions",
  /** column name */
  Resources = "resources",
  /** column name */
  ThreadId = "threadId",
  /** column name */
  TraceId = "traceId",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WithFollowUp = "with_follow_up",
}

/** select "research_aggregate_bool_exp_bool_and_arguments_columns" columns of table "research" */
export enum Research_Select_Column_Research_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsShared = "is_shared",
  /** column name */
  WithFollowUp = "with_follow_up",
}

/** select "research_aggregate_bool_exp_bool_or_arguments_columns" columns of table "research" */
export enum Research_Select_Column_Research_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsShared = "is_shared",
  /** column name */
  WithFollowUp = "with_follow_up",
}

/** input type for updating data in table "research" */
export type Research_Set_Input = {
  chat?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_shared?: InputMaybe<Scalars["Boolean"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  related_questions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resources?: InputMaybe<Scalars["jsonb"]["input"]>;
  threadId?: InputMaybe<Scalars["String"]["input"]>;
  traceId?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
  with_follow_up?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Streaming cursor of the table "research" */
export type Research_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Research_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Research_Stream_Cursor_Value_Input = {
  chat?: InputMaybe<Scalars["jsonb"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  is_shared?: InputMaybe<Scalars["Boolean"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  related_questions?: InputMaybe<Array<Scalars["String"]["input"]>>;
  resources?: InputMaybe<Scalars["jsonb"]["input"]>;
  threadId?: InputMaybe<Scalars["String"]["input"]>;
  traceId?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
  with_follow_up?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** update columns of table "research" */
export enum Research_Update_Column {
  /** column name */
  Chat = "chat",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Html = "html",
  /** column name */
  Id = "id",
  /** column name */
  IsShared = "is_shared",
  /** column name */
  Question = "question",
  /** column name */
  RelatedQuestions = "related_questions",
  /** column name */
  Resources = "resources",
  /** column name */
  ThreadId = "threadId",
  /** column name */
  TraceId = "traceId",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WithFollowUp = "with_follow_up",
}

export type Research_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Research_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Research_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Research_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Research_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Research_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Research_Set_Input>;
  /** filter the rows which have to be updated */
  where: Research_Bool_Exp;
};

/** columns and relationships of "scraper_state" */
export type Scraper_State = {
  __typename?: "scraper_state";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/** aggregated selection of "scraper_state" */
export type Scraper_State_Aggregate = {
  __typename?: "scraper_state_aggregate";
  aggregate?: Maybe<Scraper_State_Aggregate_Fields>;
  nodes: Array<Scraper_State>;
};

/** aggregate fields of "scraper_state" */
export type Scraper_State_Aggregate_Fields = {
  __typename?: "scraper_state_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Scraper_State_Max_Fields>;
  min?: Maybe<Scraper_State_Min_Fields>;
};

/** aggregate fields of "scraper_state" */
export type Scraper_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scraper_State_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Boolean expression to filter rows from the table "scraper_state". All fields are combined with a logical 'AND'. */
export type Scraper_State_Bool_Exp = {
  _and?: InputMaybe<Array<Scraper_State_Bool_Exp>>;
  _not?: InputMaybe<Scraper_State_Bool_Exp>;
  _or?: InputMaybe<Array<Scraper_State_Bool_Exp>>;
  key?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scraper_state" */
export enum Scraper_State_Constraint {
  /** unique or primary key constraint on columns "key" */
  ScraperStatePkey = "scraper_state_pkey",
}

/** input type for inserting data into table "scraper_state" */
export type Scraper_State_Insert_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** aggregate max on columns */
export type Scraper_State_Max_Fields = {
  __typename?: "scraper_state_max_fields";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** aggregate min on columns */
export type Scraper_State_Min_Fields = {
  __typename?: "scraper_state_min_fields";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

/** response of any mutation on the table "scraper_state" */
export type Scraper_State_Mutation_Response = {
  __typename?: "scraper_state_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Scraper_State>;
};

/** on_conflict condition type for table "scraper_state" */
export type Scraper_State_On_Conflict = {
  constraint: Scraper_State_Constraint;
  update_columns?: Array<Scraper_State_Update_Column>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

/** Ordering options when selecting data from "scraper_state". */
export type Scraper_State_Order_By = {
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: scraper_state */
export type Scraper_State_Pk_Columns_Input = {
  key: Scalars["String"]["input"];
};

/** select columns of table "scraper_state" */
export enum Scraper_State_Select_Column {
  /** column name */
  Key = "key",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "scraper_state" */
export type Scraper_State_Set_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** Streaming cursor of the table "scraper_state" */
export type Scraper_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scraper_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scraper_State_Stream_Cursor_Value_Input = {
  key?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** update columns of table "scraper_state" */
export enum Scraper_State_Update_Column {
  /** column name */
  Key = "key",
  /** column name */
  Value = "value",
}

export type Scraper_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Scraper_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Scraper_State_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "case_law_ids_nsoud" */
  case_law_ids_nsoud: Array<Case_Law_Ids_Nsoud>;
  /** fetch aggregated fields from the table: "case_law_ids_nsoud" */
  case_law_ids_nsoud_aggregate: Case_Law_Ids_Nsoud_Aggregate;
  /** fetch data from the table: "case_law_ids_nsoud" using primary key columns */
  case_law_ids_nsoud_by_pk?: Maybe<Case_Law_Ids_Nsoud>;
  /** fetch data from the table in a streaming manner: "case_law_ids_nsoud" */
  case_law_ids_nsoud_stream: Array<Case_Law_Ids_Nsoud>;
  /** fetch data from the table: "case_law_ids_nssoud" */
  case_law_ids_nssoud: Array<Case_Law_Ids_Nssoud>;
  /** fetch aggregated fields from the table: "case_law_ids_nssoud" */
  case_law_ids_nssoud_aggregate: Case_Law_Ids_Nssoud_Aggregate;
  /** fetch data from the table: "case_law_ids_nssoud" using primary key columns */
  case_law_ids_nssoud_by_pk?: Maybe<Case_Law_Ids_Nssoud>;
  /** fetch data from the table in a streaming manner: "case_law_ids_nssoud" */
  case_law_ids_nssoud_stream: Array<Case_Law_Ids_Nssoud>;
  /** fetch data from the table: "case_law_ids_usoud" */
  case_law_ids_usoud: Array<Case_Law_Ids_Usoud>;
  /** fetch aggregated fields from the table: "case_law_ids_usoud" */
  case_law_ids_usoud_aggregate: Case_Law_Ids_Usoud_Aggregate;
  /** fetch data from the table: "case_law_ids_usoud" using primary key columns */
  case_law_ids_usoud_by_pk?: Maybe<Case_Law_Ids_Usoud>;
  /** fetch data from the table in a streaming manner: "case_law_ids_usoud" */
  case_law_ids_usoud_stream: Array<Case_Law_Ids_Usoud>;
  /** An array relationship */
  email_verification_requests: Array<Email_Verification_Requests>;
  /** An aggregate relationship */
  email_verification_requests_aggregate: Email_Verification_Requests_Aggregate;
  /** fetch data from the table: "email_verification_requests" using primary key columns */
  email_verification_requests_by_pk?: Maybe<Email_Verification_Requests>;
  /** fetch data from the table in a streaming manner: "email_verification_requests" */
  email_verification_requests_stream: Array<Email_Verification_Requests>;
  /** fetch data from the table: "legislation_ids" */
  legislation_ids: Array<Legislation_Ids>;
  /** fetch aggregated fields from the table: "legislation_ids" */
  legislation_ids_aggregate: Legislation_Ids_Aggregate;
  /** fetch data from the table: "legislation_ids" using primary key columns */
  legislation_ids_by_pk?: Maybe<Legislation_Ids>;
  /** fetch data from the table in a streaming manner: "legislation_ids" */
  legislation_ids_stream: Array<Legislation_Ids>;
  /** fetch data from the table: "my_user" */
  my_user: Array<My_User>;
  /** fetch aggregated fields from the table: "my_user" */
  my_user_aggregate: My_User_Aggregate;
  /** fetch data from the table in a streaming manner: "my_user" */
  my_user_stream: Array<My_User>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** An array relationship */
  password_reset_requests: Array<Password_Reset_Requests>;
  /** An aggregate relationship */
  password_reset_requests_aggregate: Password_Reset_Requests_Aggregate;
  /** fetch data from the table: "password_reset_requests" using primary key columns */
  password_reset_requests_by_pk?: Maybe<Password_Reset_Requests>;
  /** fetch data from the table in a streaming manner: "password_reset_requests" */
  password_reset_requests_stream: Array<Password_Reset_Requests>;
  /** fetch data from the table: "raw_case_law_all" */
  raw_case_law_all: Array<Raw_Case_Law_All>;
  /** fetch aggregated fields from the table: "raw_case_law_all" */
  raw_case_law_all_aggregate: Raw_Case_Law_All_Aggregate;
  /** fetch data from the table in a streaming manner: "raw_case_law_all" */
  raw_case_law_all_stream: Array<Raw_Case_Law_All>;
  /** fetch data from the table: "raw_case_law_nsoud" */
  raw_case_law_nsoud: Array<Raw_Case_Law_Nsoud>;
  /** fetch aggregated fields from the table: "raw_case_law_nsoud" */
  raw_case_law_nsoud_aggregate: Raw_Case_Law_Nsoud_Aggregate;
  /** fetch data from the table: "raw_case_law_nsoud" using primary key columns */
  raw_case_law_nsoud_by_pk?: Maybe<Raw_Case_Law_Nsoud>;
  /** fetch data from the table in a streaming manner: "raw_case_law_nsoud" */
  raw_case_law_nsoud_stream: Array<Raw_Case_Law_Nsoud>;
  /** fetch data from the table: "raw_case_law_nssoud" */
  raw_case_law_nssoud: Array<Raw_Case_Law_Nssoud>;
  /** fetch aggregated fields from the table: "raw_case_law_nssoud" */
  raw_case_law_nssoud_aggregate: Raw_Case_Law_Nssoud_Aggregate;
  /** fetch data from the table: "raw_case_law_nssoud" using primary key columns */
  raw_case_law_nssoud_by_pk?: Maybe<Raw_Case_Law_Nssoud>;
  /** fetch data from the table in a streaming manner: "raw_case_law_nssoud" */
  raw_case_law_nssoud_stream: Array<Raw_Case_Law_Nssoud>;
  /** fetch data from the table: "raw_case_law_usoud" */
  raw_case_law_usoud: Array<Raw_Case_Law_Usoud>;
  /** fetch aggregated fields from the table: "raw_case_law_usoud" */
  raw_case_law_usoud_aggregate: Raw_Case_Law_Usoud_Aggregate;
  /** fetch data from the table: "raw_case_law_usoud" using primary key columns */
  raw_case_law_usoud_by_pk?: Maybe<Raw_Case_Law_Usoud>;
  /** fetch data from the table in a streaming manner: "raw_case_law_usoud" */
  raw_case_law_usoud_stream: Array<Raw_Case_Law_Usoud>;
  /** fetch data from the table: "raw_legislation" */
  raw_legislation: Array<Raw_Legislation>;
  /** fetch aggregated fields from the table: "raw_legislation" */
  raw_legislation_aggregate: Raw_Legislation_Aggregate;
  /** fetch data from the table: "raw_legislation" using primary key columns */
  raw_legislation_by_pk?: Maybe<Raw_Legislation>;
  /** fetch data from the table in a streaming manner: "raw_legislation" */
  raw_legislation_stream: Array<Raw_Legislation>;
  /** An array relationship */
  research: Array<Research>;
  /** An aggregate relationship */
  research_aggregate: Research_Aggregate;
  /** fetch data from the table: "research" using primary key columns */
  research_by_pk?: Maybe<Research>;
  /** fetch data from the table in a streaming manner: "research" */
  research_stream: Array<Research>;
  /** fetch data from the table: "scraper_state" */
  scraper_state: Array<Scraper_State>;
  /** fetch aggregated fields from the table: "scraper_state" */
  scraper_state_aggregate: Scraper_State_Aggregate;
  /** fetch data from the table: "scraper_state" using primary key columns */
  scraper_state_by_pk?: Maybe<Scraper_State>;
  /** fetch data from the table in a streaming manner: "scraper_state" */
  scraper_state_stream: Array<Scraper_State>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_favorite" */
  users_favorite: Array<Users_Favorite>;
  /** fetch aggregated fields from the table: "users_favorite" */
  users_favorite_aggregate: Users_Favorite_Aggregate;
  /** fetch data from the table: "users_favorite" using primary key columns */
  users_favorite_by_pk?: Maybe<Users_Favorite>;
  /** fetch data from the table in a streaming manner: "users_favorite" */
  users_favorite_stream: Array<Users_Favorite>;
  /** fetch data from the table: "users_history" */
  users_history: Array<Users_History>;
  /** fetch aggregated fields from the table: "users_history" */
  users_history_aggregate: Users_History_Aggregate;
  /** fetch data from the table: "users_history" using primary key columns */
  users_history_by_pk?: Maybe<Users_History>;
  /** fetch data from the table in a streaming manner: "users_history" */
  users_history_stream: Array<Users_History>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};

export type Subscription_RootCase_Law_Ids_NsoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nsoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Nsoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nsoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription_RootCase_Law_Ids_Nsoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Case_Law_Ids_Nsoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Law_Ids_Nsoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_NssoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nssoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Nssoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Nssoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Subscription_RootCase_Law_Ids_Nssoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Case_Law_Ids_Nssoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Law_Ids_Nssoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_UsoudArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Usoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Usoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Case_Law_Ids_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Case_Law_Ids_Usoud_Order_By>>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

export type Subscription_RootCase_Law_Ids_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Subscription_RootCase_Law_Ids_Usoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Case_Law_Ids_Usoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Case_Law_Ids_Usoud_Bool_Exp>;
};

export type Subscription_RootEmail_Verification_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

export type Subscription_RootEmail_Verification_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

export type Subscription_RootEmail_Verification_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootEmail_Verification_Requests_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Email_Verification_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

export type Subscription_RootLegislation_IdsArgs = {
  distinct_on?: InputMaybe<Array<Legislation_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Legislation_Ids_Order_By>>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

export type Subscription_RootLegislation_Ids_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Legislation_Ids_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Legislation_Ids_Order_By>>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

export type Subscription_RootLegislation_Ids_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription_RootLegislation_Ids_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Legislation_Ids_Stream_Cursor_Input>>;
  where?: InputMaybe<Legislation_Ids_Bool_Exp>;
};

export type Subscription_RootMy_UserArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};

export type Subscription_RootMy_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<My_User_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<My_User_Order_By>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};

export type Subscription_RootMy_User_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<My_User_Stream_Cursor_Input>>;
  where?: InputMaybe<My_User_Bool_Exp>;
};

export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootOrganizations_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootPassword_Reset_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

export type Subscription_RootPassword_Reset_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

export type Subscription_RootPassword_Reset_Requests_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootPassword_Reset_Requests_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Password_Reset_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_AllArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_All_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_All_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_All_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_All_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_All_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_All_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Raw_Case_Law_All_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Case_Law_All_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_NsoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nsoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Nsoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nsoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nsoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Nsoud_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription_RootRaw_Case_Law_Nsoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Raw_Case_Law_Nsoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Case_Law_Nsoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_NssoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nssoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Nssoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Nssoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Nssoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Nssoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Subscription_RootRaw_Case_Law_Nssoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Raw_Case_Law_Nssoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Case_Law_Nssoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_UsoudArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Usoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Usoud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Case_Law_Usoud_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Case_Law_Usoud_Order_By>>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

export type Subscription_RootRaw_Case_Law_Usoud_By_PkArgs = {
  id: Scalars["Int"]["input"];
};

export type Subscription_RootRaw_Case_Law_Usoud_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Raw_Case_Law_Usoud_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Case_Law_Usoud_Bool_Exp>;
};

export type Subscription_RootRaw_LegislationArgs = {
  distinct_on?: InputMaybe<Array<Raw_Legislation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Legislation_Order_By>>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

export type Subscription_RootRaw_Legislation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Raw_Legislation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Raw_Legislation_Order_By>>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

export type Subscription_RootRaw_Legislation_By_PkArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription_RootRaw_Legislation_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Raw_Legislation_Stream_Cursor_Input>>;
  where?: InputMaybe<Raw_Legislation_Bool_Exp>;
};

export type Subscription_RootResearchArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

export type Subscription_RootResearch_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

export type Subscription_RootResearch_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootResearch_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Research_Stream_Cursor_Input>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

export type Subscription_RootScraper_StateArgs = {
  distinct_on?: InputMaybe<Array<Scraper_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Scraper_State_Order_By>>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

export type Subscription_RootScraper_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scraper_State_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Scraper_State_Order_By>>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

export type Subscription_RootScraper_State_By_PkArgs = {
  key: Scalars["String"]["input"];
};

export type Subscription_RootScraper_State_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Scraper_State_Stream_Cursor_Input>>;
  where?: InputMaybe<Scraper_State_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootUsers_FavoriteArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

export type Subscription_RootUsers_Favorite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

export type Subscription_RootUsers_Favorite_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootUsers_Favorite_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Users_Favorite_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

export type Subscription_RootUsers_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

export type Subscription_RootUsers_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

export type Subscription_RootUsers_History_By_PkArgs = {
  id: Scalars["uuid"]["input"];
};

export type Subscription_RootUsers_History_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Users_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars["Int"]["input"];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]["input"]>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: "users";
  acknowledged_not_law_advice: Scalars["Boolean"]["output"];
  acknowledged_not_law_advice_documents: Scalars["Boolean"]["output"];
  added_law_to_favorite: Scalars["Boolean"]["output"];
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations: Scalars["Int"]["output"];
  clicked_follow_up_question: Scalars["Boolean"]["output"];
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at: Scalars["timestamptz"]["output"];
  created_research: Scalars["Boolean"]["output"];
  documents_uploaded: Scalars["Int"]["output"];
  email: Scalars["String"]["output"];
  /** An array relationship */
  email_verification_requests: Array<Email_Verification_Requests>;
  /** An aggregate relationship */
  email_verification_requests_aggregate: Email_Verification_Requests_Aggregate;
  email_verified: Scalars["Boolean"]["output"];
  first_name: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  job?: Maybe<Scalars["String"]["output"]>;
  last_name: Scalars["String"]["output"];
  law_areas?: Maybe<Scalars["jsonb"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  organization_admin: Scalars["Boolean"]["output"];
  /** An array relationship */
  organization_rel: Array<Organizations>;
  /** An aggregate relationship */
  organization_rel_aggregate: Organizations_Aggregate;
  password: Scalars["String"]["output"];
  /** An array relationship */
  password_reset_requests: Array<Password_Reset_Requests>;
  /** An aggregate relationship */
  password_reset_requests_aggregate: Password_Reset_Requests_Aggregate;
  pricing: Scalars["String"]["output"];
  pricing_valid_until?: Maybe<Scalars["timestamptz"]["output"]>;
  /** An array relationship */
  research: Array<Research>;
  /** An aggregate relationship */
  research_aggregate: Research_Aggregate;
  research_generations: Scalars["Int"]["output"];
  searched_law: Scalars["Boolean"]["output"];
  super_admin: Scalars["Boolean"]["output"];
  tel?: Maybe<Scalars["String"]["output"]>;
  tried_chat_with_document: Scalars["Boolean"]["output"];
  tried_chat_with_research: Scalars["Boolean"]["output"];
  tried_research_settings: Scalars["Boolean"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  uploaded_document: Scalars["Boolean"]["output"];
  used_discount: Scalars["Boolean"]["output"];
  /** An array relationship */
  users_favorites: Array<Users_Favorite>;
  /** An aggregate relationship */
  users_favorites_aggregate: Users_Favorite_Aggregate;
  /** An array relationship */
  users_histories: Array<Users_History>;
  /** An aggregate relationship */
  users_histories_aggregate: Users_History_Aggregate;
  viewed_new_features: Scalars["Boolean"]["output"];
  viewed_onboarding: Scalars["Boolean"]["output"];
};

/** columns and relationships of "users" */
export type UsersEmail_Verification_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersEmail_Verification_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Verification_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Email_Verification_Requests_Order_By>>;
  where?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLaw_AreasArgs = {
  path?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "users" */
export type UsersOrganization_RelArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersOrganization_Rel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPassword_Reset_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPassword_Reset_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Password_Reset_Requests_Order_By>>;
  where?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersResearchArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersResearch_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Research_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Research_Order_By>>;
  where?: InputMaybe<Research_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_FavoritesArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Favorites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_Favorite_Order_By>>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_History_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  order_by?: InputMaybe<Array<Users_History_Order_By>>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: "users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: "users_aggregate_fields";
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars["Int"]["output"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: "users_avg_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  acknowledged_not_law_advice?: InputMaybe<Boolean_Comparison_Exp>;
  acknowledged_not_law_advice_documents?: InputMaybe<Boolean_Comparison_Exp>;
  added_law_to_favorite?: InputMaybe<Boolean_Comparison_Exp>;
  billing_id?: InputMaybe<String_Comparison_Exp>;
  billing_period?: InputMaybe<String_Comparison_Exp>;
  chat_generations?: InputMaybe<Int_Comparison_Exp>;
  clicked_follow_up_question?: InputMaybe<Boolean_Comparison_Exp>;
  company_size?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_research?: InputMaybe<Boolean_Comparison_Exp>;
  documents_uploaded?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verification_requests?: InputMaybe<Email_Verification_Requests_Bool_Exp>;
  email_verification_requests_aggregate?: InputMaybe<Email_Verification_Requests_Aggregate_Bool_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  law_areas?: InputMaybe<Jsonb_Comparison_Exp>;
  organization?: InputMaybe<Uuid_Comparison_Exp>;
  organization_admin?: InputMaybe<Boolean_Comparison_Exp>;
  organization_rel?: InputMaybe<Organizations_Bool_Exp>;
  organization_rel_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_reset_requests?: InputMaybe<Password_Reset_Requests_Bool_Exp>;
  password_reset_requests_aggregate?: InputMaybe<Password_Reset_Requests_Aggregate_Bool_Exp>;
  pricing?: InputMaybe<String_Comparison_Exp>;
  pricing_valid_until?: InputMaybe<Timestamptz_Comparison_Exp>;
  research?: InputMaybe<Research_Bool_Exp>;
  research_aggregate?: InputMaybe<Research_Aggregate_Bool_Exp>;
  research_generations?: InputMaybe<Int_Comparison_Exp>;
  searched_law?: InputMaybe<Boolean_Comparison_Exp>;
  super_admin?: InputMaybe<Boolean_Comparison_Exp>;
  tel?: InputMaybe<String_Comparison_Exp>;
  tried_chat_with_document?: InputMaybe<Boolean_Comparison_Exp>;
  tried_chat_with_research?: InputMaybe<Boolean_Comparison_Exp>;
  tried_research_settings?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_document?: InputMaybe<Boolean_Comparison_Exp>;
  used_discount?: InputMaybe<Boolean_Comparison_Exp>;
  users_favorites?: InputMaybe<Users_Favorite_Bool_Exp>;
  users_favorites_aggregate?: InputMaybe<Users_Favorite_Aggregate_Bool_Exp>;
  users_histories?: InputMaybe<Users_History_Bool_Exp>;
  users_histories_aggregate?: InputMaybe<Users_History_Aggregate_Bool_Exp>;
  viewed_new_features?: InputMaybe<Boolean_Comparison_Exp>;
  viewed_onboarding?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = "users_email_key",
  /** unique or primary key constraint on columns "id" */
  UsersPkey = "users_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  law_areas?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  law_areas?: InputMaybe<Scalars["Int"]["input"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  law_areas?: InputMaybe<Scalars["String"]["input"]>;
};

/** columns and relationships of "users_favorite" */
export type Users_Favorite = {
  __typename?: "users_favorite";
  created_at: Scalars["timestamptz"]["output"];
  document_id: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  user: Scalars["uuid"]["output"];
  /** An object relationship */
  user_rel: Users;
};

/** aggregated selection of "users_favorite" */
export type Users_Favorite_Aggregate = {
  __typename?: "users_favorite_aggregate";
  aggregate?: Maybe<Users_Favorite_Aggregate_Fields>;
  nodes: Array<Users_Favorite>;
};

export type Users_Favorite_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Favorite_Aggregate_Bool_Exp_Count>;
};

export type Users_Favorite_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Users_Favorite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_favorite" */
export type Users_Favorite_Aggregate_Fields = {
  __typename?: "users_favorite_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Users_Favorite_Max_Fields>;
  min?: Maybe<Users_Favorite_Min_Fields>;
};

/** aggregate fields of "users_favorite" */
export type Users_Favorite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Favorite_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "users_favorite" */
export type Users_Favorite_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Favorite_Max_Order_By>;
  min?: InputMaybe<Users_Favorite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_favorite" */
export type Users_Favorite_Arr_Rel_Insert_Input = {
  data: Array<Users_Favorite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Favorite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_favorite". All fields are combined with a logical 'AND'. */
export type Users_Favorite_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Favorite_Bool_Exp>>;
  _not?: InputMaybe<Users_Favorite_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Favorite_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Uuid_Comparison_Exp>;
  user_rel?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "users_favorite" */
export enum Users_Favorite_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFavoritePkey = "user_favorite_pkey",
}

/** input type for inserting data into table "users_favorite" */
export type Users_Favorite_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
  user_rel?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Favorite_Max_Fields = {
  __typename?: "users_favorite_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  document_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "users_favorite" */
export type Users_Favorite_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Favorite_Min_Fields = {
  __typename?: "users_favorite_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  document_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "users_favorite" */
export type Users_Favorite_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_favorite" */
export type Users_Favorite_Mutation_Response = {
  __typename?: "users_favorite_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Favorite>;
};

/** on_conflict condition type for table "users_favorite" */
export type Users_Favorite_On_Conflict = {
  constraint: Users_Favorite_Constraint;
  update_columns?: Array<Users_Favorite_Update_Column>;
  where?: InputMaybe<Users_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "users_favorite". */
export type Users_Favorite_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_rel?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: users_favorite */
export type Users_Favorite_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "users_favorite" */
export enum Users_Favorite_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
}

/** input type for updating data in table "users_favorite" */
export type Users_Favorite_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "users_favorite" */
export type Users_Favorite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Favorite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Favorite_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "users_favorite" */
export enum Users_Favorite_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
}

export type Users_Favorite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Favorite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Favorite_Bool_Exp;
};

/** columns and relationships of "users_history" */
export type Users_History = {
  __typename?: "users_history";
  created_at: Scalars["timestamptz"]["output"];
  document_id: Scalars["String"]["output"];
  id: Scalars["uuid"]["output"];
  title: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
  updated_at: Scalars["timestamptz"]["output"];
  user: Scalars["uuid"]["output"];
  /** An object relationship */
  user_rel: Users;
};

/** aggregated selection of "users_history" */
export type Users_History_Aggregate = {
  __typename?: "users_history_aggregate";
  aggregate?: Maybe<Users_History_Aggregate_Fields>;
  nodes: Array<Users_History>;
};

export type Users_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_History_Aggregate_Bool_Exp_Count>;
};

export type Users_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_History_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
  filter?: InputMaybe<Users_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_history" */
export type Users_History_Aggregate_Fields = {
  __typename?: "users_history_aggregate_fields";
  count: Scalars["Int"]["output"];
  max?: Maybe<Users_History_Max_Fields>;
  min?: Maybe<Users_History_Min_Fields>;
};

/** aggregate fields of "users_history" */
export type Users_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_History_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** order by aggregate values of table "users_history" */
export type Users_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_History_Max_Order_By>;
  min?: InputMaybe<Users_History_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_history" */
export type Users_History_Arr_Rel_Insert_Input = {
  data: Array<Users_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_history". All fields are combined with a logical 'AND'. */
export type Users_History_Bool_Exp = {
  _and?: InputMaybe<Array<Users_History_Bool_Exp>>;
  _not?: InputMaybe<Users_History_Bool_Exp>;
  _or?: InputMaybe<Array<Users_History_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Uuid_Comparison_Exp>;
  user_rel?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "users_history" */
export enum Users_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersHistoryPkey = "users_history_pkey",
}

/** input type for inserting data into table "users_history" */
export type Users_History_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
  user_rel?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_History_Max_Fields = {
  __typename?: "users_history_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  document_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by max() on columns of table "users_history" */
export type Users_History_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_History_Min_Fields = {
  __typename?: "users_history_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  document_id?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
  user?: Maybe<Scalars["uuid"]["output"]>;
};

/** order by min() on columns of table "users_history" */
export type Users_History_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_history" */
export type Users_History_Mutation_Response = {
  __typename?: "users_history_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Users_History>;
};

/** on_conflict condition type for table "users_history" */
export type Users_History_On_Conflict = {
  constraint: Users_History_Constraint;
  update_columns?: Array<Users_History_Update_Column>;
  where?: InputMaybe<Users_History_Bool_Exp>;
};

/** Ordering options when selecting data from "users_history". */
export type Users_History_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_rel?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: users_history */
export type Users_History_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** select columns of table "users_history" */
export enum Users_History_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
}

/** input type for updating data in table "users_history" */
export type Users_History_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** Streaming cursor of the table "users_history" */
export type Users_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_History_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  document_id?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  user?: InputMaybe<Scalars["uuid"]["input"]>;
};

/** update columns of table "users_history" */
export enum Users_History_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DocumentId = "document_id",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  Type = "type",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
}

export type Users_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_History_Bool_Exp;
};

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  chat_generations?: InputMaybe<Scalars["Int"]["input"]>;
  documents_uploaded?: InputMaybe<Scalars["Int"]["input"]>;
  research_generations?: InputMaybe<Scalars["Int"]["input"]>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  acknowledged_not_law_advice?: InputMaybe<Scalars["Boolean"]["input"]>;
  acknowledged_not_law_advice_documents?: InputMaybe<Scalars["Boolean"]["input"]>;
  added_law_to_favorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  billing_id?: InputMaybe<Scalars["String"]["input"]>;
  billing_period?: InputMaybe<Scalars["String"]["input"]>;
  chat_generations?: InputMaybe<Scalars["Int"]["input"]>;
  clicked_follow_up_question?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_size?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  documents_uploaded?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_verification_requests?: InputMaybe<Email_Verification_Requests_Arr_Rel_Insert_Input>;
  email_verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
  organization?: InputMaybe<Scalars["uuid"]["input"]>;
  organization_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  organization_rel?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  password_reset_requests?: InputMaybe<Password_Reset_Requests_Arr_Rel_Insert_Input>;
  pricing?: InputMaybe<Scalars["String"]["input"]>;
  pricing_valid_until?: InputMaybe<Scalars["timestamptz"]["input"]>;
  research?: InputMaybe<Research_Arr_Rel_Insert_Input>;
  research_generations?: InputMaybe<Scalars["Int"]["input"]>;
  searched_law?: InputMaybe<Scalars["Boolean"]["input"]>;
  super_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  tel?: InputMaybe<Scalars["String"]["input"]>;
  tried_chat_with_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_chat_with_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_research_settings?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uploaded_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  used_discount?: InputMaybe<Scalars["Boolean"]["input"]>;
  users_favorites?: InputMaybe<Users_Favorite_Arr_Rel_Insert_Input>;
  users_histories?: InputMaybe<Users_History_Arr_Rel_Insert_Input>;
  viewed_new_features?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_onboarding?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "users_max_fields";
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  last_name?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Scalars["String"]["output"]>;
  pricing_valid_until?: Maybe<Scalars["timestamptz"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
  tel?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "users_min_fields";
  billing_id?: Maybe<Scalars["String"]["output"]>;
  billing_period?: Maybe<Scalars["String"]["output"]>;
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  company_size?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["timestamptz"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["uuid"]["output"]>;
  job?: Maybe<Scalars["String"]["output"]>;
  last_name?: Maybe<Scalars["String"]["output"]>;
  organization?: Maybe<Scalars["uuid"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  pricing?: Maybe<Scalars["String"]["output"]>;
  pricing_valid_until?: Maybe<Scalars["timestamptz"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
  tel?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["timestamptz"]["output"]>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: "users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"]["output"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  acknowledged_not_law_advice?: InputMaybe<Order_By>;
  acknowledged_not_law_advice_documents?: InputMaybe<Order_By>;
  added_law_to_favorite?: InputMaybe<Order_By>;
  billing_id?: InputMaybe<Order_By>;
  billing_period?: InputMaybe<Order_By>;
  chat_generations?: InputMaybe<Order_By>;
  clicked_follow_up_question?: InputMaybe<Order_By>;
  company_size?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_research?: InputMaybe<Order_By>;
  documents_uploaded?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verification_requests_aggregate?: InputMaybe<Email_Verification_Requests_Aggregate_Order_By>;
  email_verified?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  law_areas?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  organization_admin?: InputMaybe<Order_By>;
  organization_rel_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  password?: InputMaybe<Order_By>;
  password_reset_requests_aggregate?: InputMaybe<Password_Reset_Requests_Aggregate_Order_By>;
  pricing?: InputMaybe<Order_By>;
  pricing_valid_until?: InputMaybe<Order_By>;
  research_aggregate?: InputMaybe<Research_Aggregate_Order_By>;
  research_generations?: InputMaybe<Order_By>;
  searched_law?: InputMaybe<Order_By>;
  super_admin?: InputMaybe<Order_By>;
  tel?: InputMaybe<Order_By>;
  tried_chat_with_document?: InputMaybe<Order_By>;
  tried_chat_with_research?: InputMaybe<Order_By>;
  tried_research_settings?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_document?: InputMaybe<Order_By>;
  used_discount?: InputMaybe<Order_By>;
  users_favorites_aggregate?: InputMaybe<Users_Favorite_Aggregate_Order_By>;
  users_histories_aggregate?: InputMaybe<Users_History_Aggregate_Order_By>;
  viewed_new_features?: InputMaybe<Order_By>;
  viewed_onboarding?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"]["input"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AcknowledgedNotLawAdvice = "acknowledged_not_law_advice",
  /** column name */
  AcknowledgedNotLawAdviceDocuments = "acknowledged_not_law_advice_documents",
  /** column name */
  AddedLawToFavorite = "added_law_to_favorite",
  /** column name */
  BillingId = "billing_id",
  /** column name */
  BillingPeriod = "billing_period",
  /** column name */
  ChatGenerations = "chat_generations",
  /** column name */
  ClickedFollowUpQuestion = "clicked_follow_up_question",
  /** column name */
  CompanySize = "company_size",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedResearch = "created_research",
  /** column name */
  DocumentsUploaded = "documents_uploaded",
  /** column name */
  Email = "email",
  /** column name */
  EmailVerified = "email_verified",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  Job = "job",
  /** column name */
  LastName = "last_name",
  /** column name */
  LawAreas = "law_areas",
  /** column name */
  Organization = "organization",
  /** column name */
  OrganizationAdmin = "organization_admin",
  /** column name */
  Password = "password",
  /** column name */
  Pricing = "pricing",
  /** column name */
  PricingValidUntil = "pricing_valid_until",
  /** column name */
  ResearchGenerations = "research_generations",
  /** column name */
  SearchedLaw = "searched_law",
  /** column name */
  SuperAdmin = "super_admin",
  /** column name */
  Tel = "tel",
  /** column name */
  TriedChatWithDocument = "tried_chat_with_document",
  /** column name */
  TriedChatWithResearch = "tried_chat_with_research",
  /** column name */
  TriedResearchSettings = "tried_research_settings",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UploadedDocument = "uploaded_document",
  /** column name */
  UsedDiscount = "used_discount",
  /** column name */
  ViewedNewFeatures = "viewed_new_features",
  /** column name */
  ViewedOnboarding = "viewed_onboarding",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  acknowledged_not_law_advice?: InputMaybe<Scalars["Boolean"]["input"]>;
  acknowledged_not_law_advice_documents?: InputMaybe<Scalars["Boolean"]["input"]>;
  added_law_to_favorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  billing_id?: InputMaybe<Scalars["String"]["input"]>;
  billing_period?: InputMaybe<Scalars["String"]["input"]>;
  chat_generations?: InputMaybe<Scalars["Int"]["input"]>;
  clicked_follow_up_question?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_size?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  documents_uploaded?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
  organization?: InputMaybe<Scalars["uuid"]["input"]>;
  organization_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  pricing?: InputMaybe<Scalars["String"]["input"]>;
  pricing_valid_until?: InputMaybe<Scalars["timestamptz"]["input"]>;
  research_generations?: InputMaybe<Scalars["Int"]["input"]>;
  searched_law?: InputMaybe<Scalars["Boolean"]["input"]>;
  super_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  tel?: InputMaybe<Scalars["String"]["input"]>;
  tried_chat_with_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_chat_with_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_research_settings?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uploaded_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  used_discount?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_new_features?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_onboarding?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: "users_stddev_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: "users_stddev_pop_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: "users_stddev_samp_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  acknowledged_not_law_advice?: InputMaybe<Scalars["Boolean"]["input"]>;
  acknowledged_not_law_advice_documents?: InputMaybe<Scalars["Boolean"]["input"]>;
  added_law_to_favorite?: InputMaybe<Scalars["Boolean"]["input"]>;
  billing_id?: InputMaybe<Scalars["String"]["input"]>;
  billing_period?: InputMaybe<Scalars["String"]["input"]>;
  chat_generations?: InputMaybe<Scalars["Int"]["input"]>;
  clicked_follow_up_question?: InputMaybe<Scalars["Boolean"]["input"]>;
  company_size?: InputMaybe<Scalars["String"]["input"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  created_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  documents_uploaded?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_verified?: InputMaybe<Scalars["Boolean"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["uuid"]["input"]>;
  job?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  law_areas?: InputMaybe<Scalars["jsonb"]["input"]>;
  organization?: InputMaybe<Scalars["uuid"]["input"]>;
  organization_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  pricing?: InputMaybe<Scalars["String"]["input"]>;
  pricing_valid_until?: InputMaybe<Scalars["timestamptz"]["input"]>;
  research_generations?: InputMaybe<Scalars["Int"]["input"]>;
  searched_law?: InputMaybe<Scalars["Boolean"]["input"]>;
  super_admin?: InputMaybe<Scalars["Boolean"]["input"]>;
  tel?: InputMaybe<Scalars["String"]["input"]>;
  tried_chat_with_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_chat_with_research?: InputMaybe<Scalars["Boolean"]["input"]>;
  tried_research_settings?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]["input"]>;
  uploaded_document?: InputMaybe<Scalars["Boolean"]["input"]>;
  used_discount?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_new_features?: InputMaybe<Scalars["Boolean"]["input"]>;
  viewed_onboarding?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: "users_sum_fields";
  chat_generations?: Maybe<Scalars["Int"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Int"]["output"]>;
  research_generations?: Maybe<Scalars["Int"]["output"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AcknowledgedNotLawAdvice = "acknowledged_not_law_advice",
  /** column name */
  AcknowledgedNotLawAdviceDocuments = "acknowledged_not_law_advice_documents",
  /** column name */
  AddedLawToFavorite = "added_law_to_favorite",
  /** column name */
  BillingId = "billing_id",
  /** column name */
  BillingPeriod = "billing_period",
  /** column name */
  ChatGenerations = "chat_generations",
  /** column name */
  ClickedFollowUpQuestion = "clicked_follow_up_question",
  /** column name */
  CompanySize = "company_size",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedResearch = "created_research",
  /** column name */
  DocumentsUploaded = "documents_uploaded",
  /** column name */
  Email = "email",
  /** column name */
  EmailVerified = "email_verified",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  Job = "job",
  /** column name */
  LastName = "last_name",
  /** column name */
  LawAreas = "law_areas",
  /** column name */
  Organization = "organization",
  /** column name */
  OrganizationAdmin = "organization_admin",
  /** column name */
  Password = "password",
  /** column name */
  Pricing = "pricing",
  /** column name */
  PricingValidUntil = "pricing_valid_until",
  /** column name */
  ResearchGenerations = "research_generations",
  /** column name */
  SearchedLaw = "searched_law",
  /** column name */
  SuperAdmin = "super_admin",
  /** column name */
  Tel = "tel",
  /** column name */
  TriedChatWithDocument = "tried_chat_with_document",
  /** column name */
  TriedChatWithResearch = "tried_chat_with_research",
  /** column name */
  TriedResearchSettings = "tried_research_settings",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UploadedDocument = "uploaded_document",
  /** column name */
  UsedDiscount = "used_discount",
  /** column name */
  ViewedNewFeatures = "viewed_new_features",
  /** column name */
  ViewedOnboarding = "viewed_onboarding",
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: "users_var_pop_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: "users_var_samp_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: "users_variance_fields";
  chat_generations?: Maybe<Scalars["Float"]["output"]>;
  documents_uploaded?: Maybe<Scalars["Float"]["output"]>;
  research_generations?: Maybe<Scalars["Float"]["output"]>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]["input"]>;
  _gt?: InputMaybe<Scalars["uuid"]["input"]>;
  _gte?: InputMaybe<Scalars["uuid"]["input"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]["input"]>;
  _lt?: InputMaybe<Scalars["uuid"]["input"]>;
  _lte?: InputMaybe<Scalars["uuid"]["input"]>;
  _neq?: InputMaybe<Scalars["uuid"]["input"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]["input"]>>;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  first_name: Scalars["String"]["input"];
  last_name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type SignUpMutation = { __typename?: "mutation_root"; sign_up: string };

export type SignInMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type SignInMutation = { __typename?: "mutation_root"; sign_in: { __typename?: "TokenResponse"; token: string } };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestPasswordResetMutation = { __typename?: "mutation_root"; request_password_reset: string };

export type PerformPasswordResetMutationVariables = Exact<{
  request_id: Scalars["String"]["input"];
  new_password: Scalars["String"]["input"];
}>;

export type PerformPasswordResetMutation = {
  __typename?: "mutation_root";
  perform_password_reset: { __typename?: "TokenResponse"; token: string };
};

export type ActivateAccountMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  company_size: Scalars["String"]["input"];
  job: Scalars["String"]["input"];
  law_areas: Scalars["String"]["input"];
}>;

export type ActivateAccountMutation = {
  __typename?: "mutation_root";
  activate_account: { __typename?: "TokenResponse"; token: string };
};

export type AddToFavoriteMutationVariables = Exact<{
  object: Users_Favorite_Insert_Input;
}>;

export type AddToFavoriteMutation = {
  __typename?: "mutation_root";
  insert_users_favorite_one?: { __typename?: "users_favorite"; id: any } | null;
};

export type RemoveFromFavoriteMutationVariables = Exact<{
  where: Users_Favorite_Bool_Exp;
}>;

export type RemoveFromFavoriteMutation = {
  __typename?: "mutation_root";
  delete_users_favorite?: { __typename?: "users_favorite_mutation_response"; affected_rows: number } | null;
};

export type AddToHistoryMutationVariables = Exact<{
  object: Users_History_Insert_Input;
}>;

export type AddToHistoryMutation = {
  __typename?: "mutation_root";
  insert_users_history_one?: { __typename?: "users_history"; id: any } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  object: Organizations_Insert_Input;
}>;

export type CreateOrganizationMutation = {
  __typename?: "mutation_root";
  insert_organizations_one?: { __typename?: "organizations"; id: any } | null;
};

export type CreateResearchMutationVariables = Exact<{
  object: Research_Insert_Input;
}>;

export type CreateResearchMutation = {
  __typename?: "mutation_root";
  insert_research_one?: { __typename?: "research"; id: any; question?: string | null } | null;
};

export type UpdateResearchMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  input?: InputMaybe<Research_Set_Input>;
}>;

export type UpdateResearchMutation = {
  __typename?: "mutation_root";
  update_research_by_pk?: {
    __typename?: "research";
    id: any;
    traceId?: string | null;
    question?: string | null;
    html?: string | null;
    resources?: any | null;
    related_questions?: Array<string> | null;
    threadId?: string | null;
    chat?: any | null;
    is_shared: boolean;
    with_follow_up: boolean;
  } | null;
};

export type DeleteResearchMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type DeleteResearchMutation = {
  __typename?: "mutation_root";
  delete_research_by_pk?: { __typename?: "research"; id: any } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
  input?: InputMaybe<Users_Set_Input>;
}>;

export type UpdateAccountMutation = {
  __typename?: "mutation_root";
  update_users_by_pk?: { __typename?: "users"; id: any } | null;
};

export type RequestEmailVerificationMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestEmailVerificationMutation = { __typename?: "mutation_root"; request_email_verification: boolean };

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type DeleteAccountMutation = {
  __typename?: "mutation_root";
  delete_users_by_pk?: { __typename?: "users"; id: any } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  current_password: Scalars["String"]["input"];
  new_password: Scalars["String"]["input"];
}>;

export type ChangePasswordMutation = { __typename?: "mutation_root"; change_password: string };

export type InviteUserMutationVariables = Exact<{
  first_name: Scalars["String"]["input"];
  last_name: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  pricing?: InputMaybe<Scalars["String"]["input"]>;
  organization_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InviteUserMutation = { __typename?: "mutation_root"; invite_user: boolean };

export type PerformEmailVerificationMutationVariables = Exact<{
  request_id: Scalars["String"]["input"];
}>;

export type PerformEmailVerificationMutation = {
  __typename?: "mutation_root";
  perform_email_verification: { __typename?: "VerifyEmailResponse"; email: string; token: string };
};

export type HighCourtDecisionQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type HighCourtDecisionQuery = {
  __typename?: "query_root";
  raw_case_law_nsoud_by_pk?: {
    __typename?: "raw_case_law_nsoud";
    content: string;
    decision_categories: Array<string>;
    decision_date: any;
    decision_type: string;
    ecli?: string | null;
    file_mark?: string | null;
    keywords?: Array<string> | null;
    publish_date?: any | null;
    regulations: Array<string>;
    report?: string | null;
    senate_mark?: string | null;
  } | null;
};

export type ConstitutionalCourtDecisionQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type ConstitutionalCourtDecisionQuery = {
  __typename?: "query_root";
  raw_case_law_usoud_by_pk?: {
    __typename?: "raw_case_law_usoud";
    challenged_act?: Array<string> | null;
    content: string;
    decision_category: any;
    decision_date: any;
    decision_type: string;
    ecli: string;
    file_mark: string;
    material_index: Array<string>;
    proceedings_subject?: Array<string> | null;
    publish_date: any;
    regulations: Array<string>;
    statement_type: Array<string>;
  } | null;
};

export type SupremeCourtDecisionQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type SupremeCourtDecisionQuery = {
  __typename?: "query_root";
  raw_case_law_nssoud_by_pk?: {
    __typename?: "raw_case_law_nssoud";
    content: string;
    decision_date: any;
    decision_type: string;
    ecli: string;
    file_mark: string;
    proceedings_type?: string | null;
    publish_date: any;
    statement_type: string;
  } | null;
};

export type FavoriteRowsQueryVariables = Exact<{
  where: Users_Favorite_Bool_Exp;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  order_by?: InputMaybe<Array<Users_Favorite_Order_By> | Users_Favorite_Order_By>;
}>;

export type FavoriteRowsQuery = {
  __typename?: "query_root";
  info: {
    __typename?: "users_favorite_aggregate";
    aggregate?: { __typename?: "users_favorite_aggregate_fields"; totalCount: number } | null;
  };
  rows: Array<{ __typename?: "users_favorite"; title: string; created_at: any; type: string; document_id: string }>;
};

export type FavoriteQueryVariables = Exact<{
  where: Users_Favorite_Bool_Exp;
}>;

export type FavoriteQuery = {
  __typename?: "query_root";
  users_favorite_aggregate: {
    __typename?: "users_favorite_aggregate";
    aggregate?: { __typename?: "users_favorite_aggregate_fields"; count: number } | null;
  };
};

export type HistoryRowsQueryVariables = Exact<{
  where: Users_History_Bool_Exp;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  order_by?: InputMaybe<Array<Users_History_Order_By> | Users_History_Order_By>;
}>;

export type HistoryRowsQuery = {
  __typename?: "query_root";
  info: {
    __typename?: "users_history_aggregate";
    aggregate?: { __typename?: "users_history_aggregate_fields"; totalCount: number } | null;
  };
  rows: Array<{ __typename?: "users_history"; title: string; created_at: any; type: string; document_id: string }>;
};

export type LegislationQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type LegislationQuery = {
  __typename?: "query_root";
  raw_legislation_by_pk?: {
    __typename?: "raw_legislation";
    cancelled_at?: any | null;
    content: string;
    effective_from?: any | null;
    id: string;
    is_outdated: boolean;
    systematic_index?: Array<string> | null;
    title: string;
    type: string;
    valid_from?: any | null;
    version?: string | null;
  } | null;
};

export type LegislationListQueryVariables = Exact<{ [key: string]: never }>;

export type LegislationListQuery = {
  __typename?: "query_root";
  raw_legislation: Array<{ __typename?: "raw_legislation"; title: string }>;
};

export type OrganizationsRowsQueryVariables = Exact<{
  where: Organizations_Bool_Exp;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  order_by?: InputMaybe<Array<Organizations_Order_By> | Organizations_Order_By>;
}>;

export type OrganizationsRowsQuery = {
  __typename?: "query_root";
  info: {
    __typename?: "organizations_aggregate";
    aggregate?: { __typename?: "organizations_aggregate_fields"; totalCount: number } | null;
  };
  rows: Array<{ __typename?: "organizations"; id: any; title: string; ico: string; expiration: any }>;
};

export type ResearchQueryVariables = Exact<{
  where: Research_Bool_Exp;
}>;

export type ResearchQuery = {
  __typename?: "query_root";
  research: Array<{
    __typename?: "research";
    id: any;
    traceId?: string | null;
    question?: string | null;
    html?: string | null;
    resources?: any | null;
    related_questions?: Array<string> | null;
    threadId?: string | null;
    chat?: any | null;
    is_shared: boolean;
    with_follow_up: boolean;
  }>;
};

export type ResearchDetailQueryVariables = Exact<{
  id: Scalars["uuid"]["input"];
}>;

export type ResearchDetailQuery = {
  __typename?: "query_root";
  research_by_pk?: {
    __typename?: "research";
    question?: string | null;
    html?: string | null;
    resources?: any | null;
    updated_at: any;
    is_shared: boolean;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "query_root";
  my_user: Array<{
    __typename?: "my_user";
    id?: any | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    added_law_to_favorite?: boolean | null;
    clicked_follow_up_question?: boolean | null;
    tried_chat_with_research?: boolean | null;
    tried_research_settings?: boolean | null;
    created_research?: boolean | null;
    uploaded_document?: boolean | null;
    searched_law?: boolean | null;
    viewed_onboarding?: boolean | null;
    pricing?: string | null;
    research_generations?: number | null;
    documents_uploaded?: number | null;
    billing_id?: string | null;
    billing_period?: string | null;
    used_discount?: boolean | null;
    viewed_new_features?: boolean | null;
    organization?: any | null;
    organization_name?: string | null;
    organization_admin?: boolean | null;
    organization_ico?: string | null;
    organization_expiration?: any | null;
    super_admin?: boolean | null;
    acknowledged_not_law_advice?: boolean | null;
    acknowledged_not_law_advice_documents?: boolean | null;
  }>;
};

export type EmailVerificationRequestsByUserIdQueryVariables = Exact<{
  where: Email_Verification_Requests_Bool_Exp;
}>;

export type EmailVerificationRequestsByUserIdQuery = {
  __typename?: "query_root";
  email_verification_requests: Array<{ __typename?: "email_verification_requests"; email: string; finished: boolean }>;
};

export type UsersRowsQueryVariables = Exact<{
  where: Users_Bool_Exp;
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  order_by?: InputMaybe<Array<Users_Order_By> | Users_Order_By>;
}>;

export type UsersRowsQuery = {
  __typename?: "query_root";
  info: {
    __typename?: "users_aggregate";
    aggregate?: { __typename?: "users_aggregate_fields"; totalCount: number } | null;
  };
  rows: Array<{
    __typename?: "users";
    first_name: string;
    last_name: string;
    email: string;
    pricing: string;
    organization?: any | null;
    created_at: any;
    research_generations: number;
    documents_uploaded: number;
  }>;
};

export type OrganizationUsersCountQueryVariables = Exact<{
  where: Users_Bool_Exp;
}>;

export type OrganizationUsersCountQuery = {
  __typename?: "query_root";
  users_aggregate: {
    __typename?: "users_aggregate";
    aggregate?: { __typename?: "users_aggregate_fields"; count: number } | null;
  };
};

export type UsersByOrganizationQueryVariables = Exact<{
  where: Users_Bool_Exp;
}>;

export type UsersByOrganizationQuery = {
  __typename?: "query_root";
  users: Array<{
    __typename?: "users";
    first_name: string;
    last_name: string;
    email: string;
    pricing: string;
    organization_admin: boolean;
  }>;
};

export const SignUpDocument = gql`
  mutation SignUp($email: String!, $first_name: String!, $last_name: String!, $password: String!) {
    sign_up(email: $email, first_name: $first_name, last_name: $last_name, password: $password)
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignInDocument = gql`
  mutation SignIn($email: String!, $password: String!) {
    sign_in(email: $email, password: $password) {
      token
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($email: String!) {
    request_password_reset(email: $email)
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const PerformPasswordResetDocument = gql`
  mutation PerformPasswordReset($request_id: String!, $new_password: String!) {
    perform_password_reset(request_id: $request_id, new_password: $new_password) {
      token
    }
  }
`;
export type PerformPasswordResetMutationFn = Apollo.MutationFunction<
  PerformPasswordResetMutation,
  PerformPasswordResetMutationVariables
>;

/**
 * __usePerformPasswordResetMutation__
 *
 * To run a mutation, you first call `usePerformPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performPasswordResetMutation, { data, loading, error }] = usePerformPasswordResetMutation({
 *   variables: {
 *      request_id: // value for 'request_id'
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function usePerformPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<PerformPasswordResetMutation, PerformPasswordResetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PerformPasswordResetMutation, PerformPasswordResetMutationVariables>(
    PerformPasswordResetDocument,
    options
  );
}
export type PerformPasswordResetMutationHookResult = ReturnType<typeof usePerformPasswordResetMutation>;
export type PerformPasswordResetMutationResult = Apollo.MutationResult<PerformPasswordResetMutation>;
export type PerformPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PerformPasswordResetMutation,
  PerformPasswordResetMutationVariables
>;
export const ActivateAccountDocument = gql`
  mutation ActivateAccount($id: String!, $company_size: String!, $job: String!, $law_areas: String!) {
    activate_account(request_id: $id, company_size: $company_size, job: $job, law_areas: $law_areas) {
      token
    }
  }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company_size: // value for 'company_size'
 *      job: // value for 'job'
 *      law_areas: // value for 'law_areas'
 *   },
 * });
 */
export function useActivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
    ActivateAccountDocument,
    options
  );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;
export const AddToFavoriteDocument = gql`
  mutation AddToFavorite($object: users_favorite_insert_input!) {
    insert_users_favorite_one(object: $object) {
      id
    }
  }
`;
export type AddToFavoriteMutationFn = Apollo.MutationFunction<AddToFavoriteMutation, AddToFavoriteMutationVariables>;

/**
 * __useAddToFavoriteMutation__
 *
 * To run a mutation, you first call `useAddToFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToFavoriteMutation, { data, loading, error }] = useAddToFavoriteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddToFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToFavoriteMutation, AddToFavoriteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToFavoriteMutation, AddToFavoriteMutationVariables>(AddToFavoriteDocument, options);
}
export type AddToFavoriteMutationHookResult = ReturnType<typeof useAddToFavoriteMutation>;
export type AddToFavoriteMutationResult = Apollo.MutationResult<AddToFavoriteMutation>;
export type AddToFavoriteMutationOptions = Apollo.BaseMutationOptions<
  AddToFavoriteMutation,
  AddToFavoriteMutationVariables
>;
export const RemoveFromFavoriteDocument = gql`
  mutation RemoveFromFavorite($where: users_favorite_bool_exp!) {
    delete_users_favorite(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveFromFavoriteMutationFn = Apollo.MutationFunction<
  RemoveFromFavoriteMutation,
  RemoveFromFavoriteMutationVariables
>;

/**
 * __useRemoveFromFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveFromFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromFavoriteMutation, { data, loading, error }] = useRemoveFromFavoriteMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveFromFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromFavoriteMutation, RemoveFromFavoriteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromFavoriteMutation, RemoveFromFavoriteMutationVariables>(
    RemoveFromFavoriteDocument,
    options
  );
}
export type RemoveFromFavoriteMutationHookResult = ReturnType<typeof useRemoveFromFavoriteMutation>;
export type RemoveFromFavoriteMutationResult = Apollo.MutationResult<RemoveFromFavoriteMutation>;
export type RemoveFromFavoriteMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromFavoriteMutation,
  RemoveFromFavoriteMutationVariables
>;
export const AddToHistoryDocument = gql`
  mutation AddToHistory($object: users_history_insert_input!) {
    insert_users_history_one(object: $object) {
      id
    }
  }
`;
export type AddToHistoryMutationFn = Apollo.MutationFunction<AddToHistoryMutation, AddToHistoryMutationVariables>;

/**
 * __useAddToHistoryMutation__
 *
 * To run a mutation, you first call `useAddToHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToHistoryMutation, { data, loading, error }] = useAddToHistoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddToHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToHistoryMutation, AddToHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToHistoryMutation, AddToHistoryMutationVariables>(AddToHistoryDocument, options);
}
export type AddToHistoryMutationHookResult = ReturnType<typeof useAddToHistoryMutation>;
export type AddToHistoryMutationResult = Apollo.MutationResult<AddToHistoryMutation>;
export type AddToHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddToHistoryMutation,
  AddToHistoryMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($object: organizations_insert_input!) {
    insert_organizations_one(object: $object) {
      id
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const CreateResearchDocument = gql`
  mutation CreateResearch($object: research_insert_input!) {
    insert_research_one(object: $object) {
      id
      question
    }
  }
`;
export type CreateResearchMutationFn = Apollo.MutationFunction<CreateResearchMutation, CreateResearchMutationVariables>;

/**
 * __useCreateResearchMutation__
 *
 * To run a mutation, you first call `useCreateResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResearchMutation, { data, loading, error }] = useCreateResearchMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateResearchMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateResearchMutation, CreateResearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateResearchMutation, CreateResearchMutationVariables>(CreateResearchDocument, options);
}
export type CreateResearchMutationHookResult = ReturnType<typeof useCreateResearchMutation>;
export type CreateResearchMutationResult = Apollo.MutationResult<CreateResearchMutation>;
export type CreateResearchMutationOptions = Apollo.BaseMutationOptions<
  CreateResearchMutation,
  CreateResearchMutationVariables
>;
export const UpdateResearchDocument = gql`
  mutation UpdateResearch($id: uuid!, $input: research_set_input) {
    update_research_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      traceId
      question
      html
      resources
      related_questions
      threadId
      chat
      is_shared
      with_follow_up
    }
  }
`;
export type UpdateResearchMutationFn = Apollo.MutationFunction<UpdateResearchMutation, UpdateResearchMutationVariables>;

/**
 * __useUpdateResearchMutation__
 *
 * To run a mutation, you first call `useUpdateResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResearchMutation, { data, loading, error }] = useUpdateResearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResearchMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateResearchMutation, UpdateResearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateResearchMutation, UpdateResearchMutationVariables>(UpdateResearchDocument, options);
}
export type UpdateResearchMutationHookResult = ReturnType<typeof useUpdateResearchMutation>;
export type UpdateResearchMutationResult = Apollo.MutationResult<UpdateResearchMutation>;
export type UpdateResearchMutationOptions = Apollo.BaseMutationOptions<
  UpdateResearchMutation,
  UpdateResearchMutationVariables
>;
export const DeleteResearchDocument = gql`
  mutation DeleteResearch($id: uuid!) {
    delete_research_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteResearchMutationFn = Apollo.MutationFunction<DeleteResearchMutation, DeleteResearchMutationVariables>;

/**
 * __useDeleteResearchMutation__
 *
 * To run a mutation, you first call `useDeleteResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResearchMutation, { data, loading, error }] = useDeleteResearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResearchMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteResearchMutation, DeleteResearchMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteResearchMutation, DeleteResearchMutationVariables>(DeleteResearchDocument, options);
}
export type DeleteResearchMutationHookResult = ReturnType<typeof useDeleteResearchMutation>;
export type DeleteResearchMutationResult = Apollo.MutationResult<DeleteResearchMutation>;
export type DeleteResearchMutationOptions = Apollo.BaseMutationOptions<
  DeleteResearchMutation,
  DeleteResearchMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($id: uuid!, $input: users_set_input) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const RequestEmailVerificationDocument = gql`
  mutation RequestEmailVerification($email: String!) {
    request_email_verification(email: $email)
  }
`;
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>;

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestEmailVerificationMutation, RequestEmailVerificationMutationVariables>(
    RequestEmailVerificationDocument,
    options
  );
}
export type RequestEmailVerificationMutationHookResult = ReturnType<typeof useRequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationResult = Apollo.MutationResult<RequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestEmailVerificationMutation,
  RequestEmailVerificationMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($id: uuid!) {
    delete_users_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($current_password: String!, $new_password: String!) {
    change_password(new_password: $new_password, password: $current_password)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      current_password: // value for 'current_password'
 *      new_password: // value for 'new_password'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser(
    $first_name: String!
    $last_name: String!
    $email: String!
    $pricing: String
    $organization_id: String
  ) {
    invite_user(
      first_name: $first_name
      last_name: $last_name
      email: $email
      pricing: $pricing
      organization_id: $organization_id
    )
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      pricing: // value for 'pricing'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const PerformEmailVerificationDocument = gql`
  mutation PerformEmailVerification($request_id: String!) {
    perform_email_verification(request_id: $request_id) {
      email
      token
    }
  }
`;
export type PerformEmailVerificationMutationFn = Apollo.MutationFunction<
  PerformEmailVerificationMutation,
  PerformEmailVerificationMutationVariables
>;

/**
 * __usePerformEmailVerificationMutation__
 *
 * To run a mutation, you first call `usePerformEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performEmailVerificationMutation, { data, loading, error }] = usePerformEmailVerificationMutation({
 *   variables: {
 *      request_id: // value for 'request_id'
 *   },
 * });
 */
export function usePerformEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<PerformEmailVerificationMutation, PerformEmailVerificationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PerformEmailVerificationMutation, PerformEmailVerificationMutationVariables>(
    PerformEmailVerificationDocument,
    options
  );
}
export type PerformEmailVerificationMutationHookResult = ReturnType<typeof usePerformEmailVerificationMutation>;
export type PerformEmailVerificationMutationResult = Apollo.MutationResult<PerformEmailVerificationMutation>;
export type PerformEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  PerformEmailVerificationMutation,
  PerformEmailVerificationMutationVariables
>;
export const HighCourtDecisionDocument = gql`
  query HighCourtDecision($id: String!) {
    raw_case_law_nsoud_by_pk(id: $id) {
      content
      decision_categories
      decision_date
      decision_type
      ecli
      file_mark
      keywords
      publish_date
      regulations
      report
      senate_mark
    }
  }
`;

/**
 * __useHighCourtDecisionQuery__
 *
 * To run a query within a React component, call `useHighCourtDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighCourtDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighCourtDecisionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHighCourtDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<HighCourtDecisionQuery, HighCourtDecisionQueryVariables> &
    ({ variables: HighCourtDecisionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>(HighCourtDecisionDocument, options);
}
export function useHighCourtDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>(
    HighCourtDecisionDocument,
    options
  );
}
export function useHighCourtDecisionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>(
    HighCourtDecisionDocument,
    options
  );
}
export type HighCourtDecisionQueryHookResult = ReturnType<typeof useHighCourtDecisionQuery>;
export type HighCourtDecisionLazyQueryHookResult = ReturnType<typeof useHighCourtDecisionLazyQuery>;
export type HighCourtDecisionSuspenseQueryHookResult = ReturnType<typeof useHighCourtDecisionSuspenseQuery>;
export type HighCourtDecisionQueryResult = Apollo.QueryResult<HighCourtDecisionQuery, HighCourtDecisionQueryVariables>;
export const ConstitutionalCourtDecisionDocument = gql`
  query ConstitutionalCourtDecision($id: Int!) {
    raw_case_law_usoud_by_pk(id: $id) {
      challenged_act
      content
      decision_category
      decision_date
      decision_type
      ecli
      file_mark
      material_index
      proceedings_subject
      publish_date
      regulations
      statement_type
    }
  }
`;

/**
 * __useConstitutionalCourtDecisionQuery__
 *
 * To run a query within a React component, call `useConstitutionalCourtDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useConstitutionalCourtDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConstitutionalCourtDecisionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConstitutionalCourtDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<ConstitutionalCourtDecisionQuery, ConstitutionalCourtDecisionQueryVariables> &
    ({ variables: ConstitutionalCourtDecisionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConstitutionalCourtDecisionQuery, ConstitutionalCourtDecisionQueryVariables>(
    ConstitutionalCourtDecisionDocument,
    options
  );
}
export function useConstitutionalCourtDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConstitutionalCourtDecisionQuery, ConstitutionalCourtDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConstitutionalCourtDecisionQuery, ConstitutionalCourtDecisionQueryVariables>(
    ConstitutionalCourtDecisionDocument,
    options
  );
}
export function useConstitutionalCourtDecisionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ConstitutionalCourtDecisionQuery,
    ConstitutionalCourtDecisionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ConstitutionalCourtDecisionQuery, ConstitutionalCourtDecisionQueryVariables>(
    ConstitutionalCourtDecisionDocument,
    options
  );
}
export type ConstitutionalCourtDecisionQueryHookResult = ReturnType<typeof useConstitutionalCourtDecisionQuery>;
export type ConstitutionalCourtDecisionLazyQueryHookResult = ReturnType<typeof useConstitutionalCourtDecisionLazyQuery>;
export type ConstitutionalCourtDecisionSuspenseQueryHookResult = ReturnType<
  typeof useConstitutionalCourtDecisionSuspenseQuery
>;
export type ConstitutionalCourtDecisionQueryResult = Apollo.QueryResult<
  ConstitutionalCourtDecisionQuery,
  ConstitutionalCourtDecisionQueryVariables
>;
export const SupremeCourtDecisionDocument = gql`
  query SupremeCourtDecision($id: Int!) {
    raw_case_law_nssoud_by_pk(id: $id) {
      content
      decision_date
      decision_type
      ecli
      file_mark
      proceedings_type
      publish_date
      statement_type
    }
  }
`;

/**
 * __useSupremeCourtDecisionQuery__
 *
 * To run a query within a React component, call `useSupremeCourtDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupremeCourtDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupremeCourtDecisionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupremeCourtDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables> &
    ({ variables: SupremeCourtDecisionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables>(
    SupremeCourtDecisionDocument,
    options
  );
}
export function useSupremeCourtDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables>(
    SupremeCourtDecisionDocument,
    options
  );
}
export function useSupremeCourtDecisionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SupremeCourtDecisionQuery, SupremeCourtDecisionQueryVariables>(
    SupremeCourtDecisionDocument,
    options
  );
}
export type SupremeCourtDecisionQueryHookResult = ReturnType<typeof useSupremeCourtDecisionQuery>;
export type SupremeCourtDecisionLazyQueryHookResult = ReturnType<typeof useSupremeCourtDecisionLazyQuery>;
export type SupremeCourtDecisionSuspenseQueryHookResult = ReturnType<typeof useSupremeCourtDecisionSuspenseQuery>;
export type SupremeCourtDecisionQueryResult = Apollo.QueryResult<
  SupremeCourtDecisionQuery,
  SupremeCourtDecisionQueryVariables
>;
export const FavoriteRowsDocument = gql`
  query FavoriteRows(
    $where: users_favorite_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [users_favorite_order_by!]
  ) {
    info: users_favorite_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    rows: users_favorite(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      title
      created_at
      type
      document_id
    }
  }
`;

/**
 * __useFavoriteRowsQuery__
 *
 * To run a query within a React component, call `useFavoriteRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFavoriteRowsQuery(
  baseOptions: Apollo.QueryHookOptions<FavoriteRowsQuery, FavoriteRowsQueryVariables> &
    ({ variables: FavoriteRowsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteRowsQuery, FavoriteRowsQueryVariables>(FavoriteRowsDocument, options);
}
export function useFavoriteRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FavoriteRowsQuery, FavoriteRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoriteRowsQuery, FavoriteRowsQueryVariables>(FavoriteRowsDocument, options);
}
export function useFavoriteRowsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FavoriteRowsQuery, FavoriteRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FavoriteRowsQuery, FavoriteRowsQueryVariables>(FavoriteRowsDocument, options);
}
export type FavoriteRowsQueryHookResult = ReturnType<typeof useFavoriteRowsQuery>;
export type FavoriteRowsLazyQueryHookResult = ReturnType<typeof useFavoriteRowsLazyQuery>;
export type FavoriteRowsSuspenseQueryHookResult = ReturnType<typeof useFavoriteRowsSuspenseQuery>;
export type FavoriteRowsQueryResult = Apollo.QueryResult<FavoriteRowsQuery, FavoriteRowsQueryVariables>;
export const FavoriteDocument = gql`
  query Favorite($where: users_favorite_bool_exp!) {
    users_favorite_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useFavoriteQuery__
 *
 * To run a query within a React component, call `useFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFavoriteQuery(
  baseOptions: Apollo.QueryHookOptions<FavoriteQuery, FavoriteQueryVariables> &
    ({ variables: FavoriteQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteQuery, FavoriteQueryVariables>(FavoriteDocument, options);
}
export function useFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FavoriteQuery, FavoriteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoriteQuery, FavoriteQueryVariables>(FavoriteDocument, options);
}
export function useFavoriteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FavoriteQuery, FavoriteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FavoriteQuery, FavoriteQueryVariables>(FavoriteDocument, options);
}
export type FavoriteQueryHookResult = ReturnType<typeof useFavoriteQuery>;
export type FavoriteLazyQueryHookResult = ReturnType<typeof useFavoriteLazyQuery>;
export type FavoriteSuspenseQueryHookResult = ReturnType<typeof useFavoriteSuspenseQuery>;
export type FavoriteQueryResult = Apollo.QueryResult<FavoriteQuery, FavoriteQueryVariables>;
export const HistoryRowsDocument = gql`
  query HistoryRows(
    $where: users_history_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [users_history_order_by!]
  ) {
    info: users_history_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    rows: users_history(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      title
      created_at
      type
      document_id
    }
  }
`;

/**
 * __useHistoryRowsQuery__
 *
 * To run a query within a React component, call `useHistoryRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useHistoryRowsQuery(
  baseOptions: Apollo.QueryHookOptions<HistoryRowsQuery, HistoryRowsQueryVariables> &
    ({ variables: HistoryRowsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoryRowsQuery, HistoryRowsQueryVariables>(HistoryRowsDocument, options);
}
export function useHistoryRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HistoryRowsQuery, HistoryRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoryRowsQuery, HistoryRowsQueryVariables>(HistoryRowsDocument, options);
}
export function useHistoryRowsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<HistoryRowsQuery, HistoryRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<HistoryRowsQuery, HistoryRowsQueryVariables>(HistoryRowsDocument, options);
}
export type HistoryRowsQueryHookResult = ReturnType<typeof useHistoryRowsQuery>;
export type HistoryRowsLazyQueryHookResult = ReturnType<typeof useHistoryRowsLazyQuery>;
export type HistoryRowsSuspenseQueryHookResult = ReturnType<typeof useHistoryRowsSuspenseQuery>;
export type HistoryRowsQueryResult = Apollo.QueryResult<HistoryRowsQuery, HistoryRowsQueryVariables>;
export const LegislationDocument = gql`
  query Legislation($id: String!) {
    raw_legislation_by_pk(id: $id) {
      cancelled_at
      content
      effective_from
      id
      is_outdated
      systematic_index
      title
      type
      valid_from
      version
    }
  }
`;

/**
 * __useLegislationQuery__
 *
 * To run a query within a React component, call `useLegislationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegislationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegislationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLegislationQuery(
  baseOptions: Apollo.QueryHookOptions<LegislationQuery, LegislationQueryVariables> &
    ({ variables: LegislationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegislationQuery, LegislationQueryVariables>(LegislationDocument, options);
}
export function useLegislationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegislationQuery, LegislationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegislationQuery, LegislationQueryVariables>(LegislationDocument, options);
}
export function useLegislationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LegislationQuery, LegislationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegislationQuery, LegislationQueryVariables>(LegislationDocument, options);
}
export type LegislationQueryHookResult = ReturnType<typeof useLegislationQuery>;
export type LegislationLazyQueryHookResult = ReturnType<typeof useLegislationLazyQuery>;
export type LegislationSuspenseQueryHookResult = ReturnType<typeof useLegislationSuspenseQuery>;
export type LegislationQueryResult = Apollo.QueryResult<LegislationQuery, LegislationQueryVariables>;
export const LegislationListDocument = gql`
  query LegislationList {
    raw_legislation {
      title
    }
  }
`;

/**
 * __useLegislationListQuery__
 *
 * To run a query within a React component, call `useLegislationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegislationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegislationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegislationListQuery(
  baseOptions?: Apollo.QueryHookOptions<LegislationListQuery, LegislationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegislationListQuery, LegislationListQueryVariables>(LegislationListDocument, options);
}
export function useLegislationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegislationListQuery, LegislationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegislationListQuery, LegislationListQueryVariables>(LegislationListDocument, options);
}
export function useLegislationListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LegislationListQuery, LegislationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LegislationListQuery, LegislationListQueryVariables>(LegislationListDocument, options);
}
export type LegislationListQueryHookResult = ReturnType<typeof useLegislationListQuery>;
export type LegislationListLazyQueryHookResult = ReturnType<typeof useLegislationListLazyQuery>;
export type LegislationListSuspenseQueryHookResult = ReturnType<typeof useLegislationListSuspenseQuery>;
export type LegislationListQueryResult = Apollo.QueryResult<LegislationListQuery, LegislationListQueryVariables>;
export const OrganizationsRowsDocument = gql`
  query OrganizationsRows(
    $where: organizations_bool_exp!
    $limit: Int!
    $offset: Int!
    $order_by: [organizations_order_by!]
  ) {
    info: organizations_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    rows: organizations(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      title
      ico
      expiration
    }
  }
`;

/**
 * __useOrganizationsRowsQuery__
 *
 * To run a query within a React component, call `useOrganizationsRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useOrganizationsRowsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationsRowsQuery, OrganizationsRowsQueryVariables> &
    ({ variables: OrganizationsRowsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>(OrganizationsRowsDocument, options);
}
export function useOrganizationsRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>(
    OrganizationsRowsDocument,
    options
  );
}
export function useOrganizationsRowsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>(
    OrganizationsRowsDocument,
    options
  );
}
export type OrganizationsRowsQueryHookResult = ReturnType<typeof useOrganizationsRowsQuery>;
export type OrganizationsRowsLazyQueryHookResult = ReturnType<typeof useOrganizationsRowsLazyQuery>;
export type OrganizationsRowsSuspenseQueryHookResult = ReturnType<typeof useOrganizationsRowsSuspenseQuery>;
export type OrganizationsRowsQueryResult = Apollo.QueryResult<OrganizationsRowsQuery, OrganizationsRowsQueryVariables>;
export const ResearchDocument = gql`
  query Research($where: research_bool_exp!) {
    research(where: $where, order_by: { created_at: desc }) {
      id
      traceId
      question
      html
      resources
      related_questions
      threadId
      chat
      is_shared
      with_follow_up
    }
  }
`;

/**
 * __useResearchQuery__
 *
 * To run a query within a React component, call `useResearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useResearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useResearchQuery(
  baseOptions: Apollo.QueryHookOptions<ResearchQuery, ResearchQueryVariables> &
    ({ variables: ResearchQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResearchQuery, ResearchQueryVariables>(ResearchDocument, options);
}
export function useResearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResearchQuery, ResearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResearchQuery, ResearchQueryVariables>(ResearchDocument, options);
}
export function useResearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ResearchQuery, ResearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ResearchQuery, ResearchQueryVariables>(ResearchDocument, options);
}
export type ResearchQueryHookResult = ReturnType<typeof useResearchQuery>;
export type ResearchLazyQueryHookResult = ReturnType<typeof useResearchLazyQuery>;
export type ResearchSuspenseQueryHookResult = ReturnType<typeof useResearchSuspenseQuery>;
export type ResearchQueryResult = Apollo.QueryResult<ResearchQuery, ResearchQueryVariables>;
export const ResearchDetailDocument = gql`
  query ResearchDetail($id: uuid!) {
    research_by_pk(id: $id) {
      question
      html
      resources
      updated_at
      is_shared
    }
  }
`;

/**
 * __useResearchDetailQuery__
 *
 * To run a query within a React component, call `useResearchDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useResearchDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResearchDetailQuery(
  baseOptions: Apollo.QueryHookOptions<ResearchDetailQuery, ResearchDetailQueryVariables> &
    ({ variables: ResearchDetailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResearchDetailQuery, ResearchDetailQueryVariables>(ResearchDetailDocument, options);
}
export function useResearchDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResearchDetailQuery, ResearchDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResearchDetailQuery, ResearchDetailQueryVariables>(ResearchDetailDocument, options);
}
export function useResearchDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ResearchDetailQuery, ResearchDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ResearchDetailQuery, ResearchDetailQueryVariables>(ResearchDetailDocument, options);
}
export type ResearchDetailQueryHookResult = ReturnType<typeof useResearchDetailQuery>;
export type ResearchDetailLazyQueryHookResult = ReturnType<typeof useResearchDetailLazyQuery>;
export type ResearchDetailSuspenseQueryHookResult = ReturnType<typeof useResearchDetailSuspenseQuery>;
export type ResearchDetailQueryResult = Apollo.QueryResult<ResearchDetailQuery, ResearchDetailQueryVariables>;
export const MeDocument = gql`
  query Me {
    my_user {
      id
      email
      first_name
      last_name
      added_law_to_favorite
      clicked_follow_up_question
      tried_chat_with_research
      tried_research_settings
      created_research
      uploaded_document
      searched_law
      viewed_onboarding
      pricing
      research_generations
      documents_uploaded
      billing_id
      billing_period
      used_discount
      viewed_new_features
      organization
      organization_name
      organization_admin
      organization_ico
      organization_expiration
      super_admin
      acknowledged_not_law_advice
      acknowledged_not_law_advice_documents
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const EmailVerificationRequestsByUserIdDocument = gql`
  query EmailVerificationRequestsByUserId($where: email_verification_requests_bool_exp!) {
    email_verification_requests(where: $where, order_by: { created_at: desc }) {
      email
      finished
    }
  }
`;

/**
 * __useEmailVerificationRequestsByUserIdQuery__
 *
 * To run a query within a React component, call `useEmailVerificationRequestsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerificationRequestsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerificationRequestsByUserIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmailVerificationRequestsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailVerificationRequestsByUserIdQuery,
    EmailVerificationRequestsByUserIdQueryVariables
  > &
    ({ variables: EmailVerificationRequestsByUserIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailVerificationRequestsByUserIdQuery, EmailVerificationRequestsByUserIdQueryVariables>(
    EmailVerificationRequestsByUserIdDocument,
    options
  );
}
export function useEmailVerificationRequestsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailVerificationRequestsByUserIdQuery,
    EmailVerificationRequestsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailVerificationRequestsByUserIdQuery, EmailVerificationRequestsByUserIdQueryVariables>(
    EmailVerificationRequestsByUserIdDocument,
    options
  );
}
export function useEmailVerificationRequestsByUserIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmailVerificationRequestsByUserIdQuery,
    EmailVerificationRequestsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    EmailVerificationRequestsByUserIdQuery,
    EmailVerificationRequestsByUserIdQueryVariables
  >(EmailVerificationRequestsByUserIdDocument, options);
}
export type EmailVerificationRequestsByUserIdQueryHookResult = ReturnType<
  typeof useEmailVerificationRequestsByUserIdQuery
>;
export type EmailVerificationRequestsByUserIdLazyQueryHookResult = ReturnType<
  typeof useEmailVerificationRequestsByUserIdLazyQuery
>;
export type EmailVerificationRequestsByUserIdSuspenseQueryHookResult = ReturnType<
  typeof useEmailVerificationRequestsByUserIdSuspenseQuery
>;
export type EmailVerificationRequestsByUserIdQueryResult = Apollo.QueryResult<
  EmailVerificationRequestsByUserIdQuery,
  EmailVerificationRequestsByUserIdQueryVariables
>;
export const UsersRowsDocument = gql`
  query UsersRows($where: users_bool_exp!, $limit: Int!, $offset: Int!, $order_by: [users_order_by!]) {
    info: users_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    rows: users(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      first_name
      last_name
      email
      pricing
      organization
      created_at
      research_generations
      documents_uploaded
    }
  }
`;

/**
 * __useUsersRowsQuery__
 *
 * To run a query within a React component, call `useUsersRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useUsersRowsQuery(
  baseOptions: Apollo.QueryHookOptions<UsersRowsQuery, UsersRowsQueryVariables> &
    ({ variables: UsersRowsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersRowsQuery, UsersRowsQueryVariables>(UsersRowsDocument, options);
}
export function useUsersRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersRowsQuery, UsersRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersRowsQuery, UsersRowsQueryVariables>(UsersRowsDocument, options);
}
export function useUsersRowsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersRowsQuery, UsersRowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersRowsQuery, UsersRowsQueryVariables>(UsersRowsDocument, options);
}
export type UsersRowsQueryHookResult = ReturnType<typeof useUsersRowsQuery>;
export type UsersRowsLazyQueryHookResult = ReturnType<typeof useUsersRowsLazyQuery>;
export type UsersRowsSuspenseQueryHookResult = ReturnType<typeof useUsersRowsSuspenseQuery>;
export type UsersRowsQueryResult = Apollo.QueryResult<UsersRowsQuery, UsersRowsQueryVariables>;
export const OrganizationUsersCountDocument = gql`
  query OrganizationUsersCount($where: users_bool_exp!) {
    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useOrganizationUsersCountQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrganizationUsersCountQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables> &
    ({ variables: OrganizationUsersCountQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables>(
    OrganizationUsersCountDocument,
    options
  );
}
export function useOrganizationUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables>(
    OrganizationUsersCountDocument,
    options
  );
}
export function useOrganizationUsersCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganizationUsersCountQuery, OrganizationUsersCountQueryVariables>(
    OrganizationUsersCountDocument,
    options
  );
}
export type OrganizationUsersCountQueryHookResult = ReturnType<typeof useOrganizationUsersCountQuery>;
export type OrganizationUsersCountLazyQueryHookResult = ReturnType<typeof useOrganizationUsersCountLazyQuery>;
export type OrganizationUsersCountSuspenseQueryHookResult = ReturnType<typeof useOrganizationUsersCountSuspenseQuery>;
export type OrganizationUsersCountQueryResult = Apollo.QueryResult<
  OrganizationUsersCountQuery,
  OrganizationUsersCountQueryVariables
>;
export const UsersByOrganizationDocument = gql`
  query UsersByOrganization($where: users_bool_exp!) {
    users(where: $where, order_by: { first_name: desc }) {
      first_name
      last_name
      email
      pricing
      organization_admin
    }
  }
`;

/**
 * __useUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByOrganizationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersByOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<UsersByOrganizationQuery, UsersByOrganizationQueryVariables> &
    ({ variables: UsersByOrganizationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersByOrganizationQuery, UsersByOrganizationQueryVariables>(
    UsersByOrganizationDocument,
    options
  );
}
export function useUsersByOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersByOrganizationQuery, UsersByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersByOrganizationQuery, UsersByOrganizationQueryVariables>(
    UsersByOrganizationDocument,
    options
  );
}
export function useUsersByOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UsersByOrganizationQuery, UsersByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersByOrganizationQuery, UsersByOrganizationQueryVariables>(
    UsersByOrganizationDocument,
    options
  );
}
export type UsersByOrganizationQueryHookResult = ReturnType<typeof useUsersByOrganizationQuery>;
export type UsersByOrganizationLazyQueryHookResult = ReturnType<typeof useUsersByOrganizationLazyQuery>;
export type UsersByOrganizationSuspenseQueryHookResult = ReturnType<typeof useUsersByOrganizationSuspenseQuery>;
export type UsersByOrganizationQueryResult = Apollo.QueryResult<
  UsersByOrganizationQuery,
  UsersByOrganizationQueryVariables
>;
